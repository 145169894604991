import React from 'react'
import { useMutation } from '@apollo/client'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { NavigationBar } from '../NavigationBar'

import { GenericTextInput } from '../inputs/TextInput'
import SelectInput from '../inputs/SelectInput'

import { Container, Footer } from '../styles/shared/ContainersStyled'
import { LowercaseFormTitle } from '../styles/shared/TitlesStyled'
import { CircleSpinner } from 'react-spinners-kit'
import { PrimaryButton } from '../styles/shared/ButtonsStyled'

import BETA_SIGN_UP from '../../mutations/SignUp'

const SignUpPage = () => {
  const [betaSignUp, betaSignUpData] = useMutation(BETA_SIGN_UP)

  return (
    <Container>
      <NavigationBar />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundColor: 'white',
          height: 'auto',
          width: '300px'
        }}
      >
        <LowercaseFormTitle>beta sign up</LowercaseFormTitle>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            occupation: { label: 'occupation', value: 'occupation' },
            organization: ''
          }}
          validationSchema={Yup.object({
            firstName: Yup.string().max(40, '^ Must be 40 characters or less').required('^ Required'),
            lastName: Yup.string().max(40, '^ Must be 40 characters or less').required('^ Required'),
            email: Yup.string().email('^ Invalid email address').required('^ Required'),
            occupation: Yup.object().shape({
              value: Yup.string()
                .oneOf(['student', 'educator', 'researcher', 'other'], '^ Invalid Occupation Type')
                .required('^ Required')
            }),
            organization: Yup.string().required('^ Required')
          })}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true)
            await betaSignUp({ variables: { ...values, occupation: values.occupation.value } })
            setSubmitting(false)
          }}
        >
          {(formikProps) => (
            <Form style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <div style={{ margin: '0 20px', textAlign: 'center' }}>
                {betaSignUpData.data?.betaSignUp}
                {betaSignUpData.error && betaSignUpData.error.message.replace('GraphQL error: ', '')}
              </div>
              <GenericTextInput label='First Name' name='firstName' type='text' placeholder='first name' />
              <GenericTextInput label='Last Name' name='lastName' type='text' placeholder='last name' />
              <GenericTextInput label='Email Address' name='email' type='email' placeholder='email' />
              <SelectInput
                label='Occupation'
                name='occupation'
                type='text'
                placeholder='occupation'
                options={[
                  { label: 'Student', value: 'student' },
                  { label: 'Researcher', value: 'researcher' },
                  { label: 'Educator', value: 'educator' },
                  { label: 'Other', value: 'other' }
                ]}
              />
              <GenericTextInput label='Organization' name='organization' type='text' placeholder='organization' />
              <div style={{ margin: '0 20px 20px 20px', textAlign: 'center' }}>
                <PrimaryButton type='submit' disabled={formikProps.isSubmitting || !formikProps.isValid}>
                  {formikProps.isSubmitting ? <CircleSpinner size={15} loading={true} /> : 'sign up'}
                </PrimaryButton>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <Footer year />
    </Container>
  )
}

export default SignUpPage
