import React from 'react'
import { useMutation } from '@apollo/client'
// import { ModelTab } from './ModelTab'
// import { ConnectionsTab } from './ConnectionsTab'
// import { DiscoveryTab } from './DiscoveryTab'

// import { useConceptModalStore } from '../stores/useConceptModalStore'

import { ConceptModal as ConnectionModalStyled } from './styles/shared/ModalsStyled'
import { ConceptHeader } from './styles/shared/ContainersStyled'
import { ConceptTitle } from './styles/shared/TitlesStyled'
// import { ConceptNavBar } from './styles/shared/NavBarsStyled'
import { ConceptNavButton } from './styles/shared/ButtonsStyled'
import { useGraphStore } from '../stores/useGraphStore'

import { DELETE_CONNECTION } from '../mutations/DeleteConnection'

export const ConnectionModal = ({ isOpen, onBackgroundClick, backgroundProps, connection, closeModal }) => {
  // const [activeTab, setActiveTab] = useConceptModalStore((state) => [state.activeTab, state.setActiveTab])
  const [graph, setGraph] = useGraphStore((state) => [state.graph, state.setGraph])

  const [deleteConnection] = useMutation(DELETE_CONNECTION, {
    refetchQueries: ['GetConceptConnections']
  })

  const getNodeById = (id) => graph.nodes.find((node) => node.id === id)

  return (
    <ConnectionModalStyled isOpen={isOpen} onBackgroundClick={onBackgroundClick} backgroundProps={backgroundProps}>
      <ConceptHeader>
        <ConceptTitle>{`${getNodeById(connection.from).label} ↔ ${getNodeById(connection.to).label}`}</ConceptTitle>

        {/* <ConceptNavBar>
          {['discovery', 'model', 'applications', 'questions'].map((tab) => (
            <ConceptNavButton
              key={tab}
              active={tab === activeTab}
              onClick={() => {
                setActiveTab(tab)
              }}
            >
              {tab}
            </ConceptNavButton>
          ))}
        </ConceptNavBar> */}
      </ConceptHeader>
      {/* <div>
        
        {activeTab === 'discovery' && <DiscoveryTab concept={concept} />}
        {activeTab === 'model' && <ModelTab concept={concept} />}
        {activeTab === 'connections' && <ConnectionsTab concept={concept} />}
        {activeTab === 'applications' && (
          <>
            <div>{activeTab}</div>
            <div>Relevance for production of value</div>
          </>
        )}
        {activeTab === 'questions' && (
          <>
            <div>{activeTab}</div>
            <div>Visual Tutor</div>
          </>
        )}
      </div> */}
      <div style={{ display: 'flex' }}>
        <ConceptNavButton
          onClick={async () => {
            const res = await deleteConnection({ variables: { connectionId: connection.id } })
            if (res?.data?.deleteConnection?.__typename === 'SuccessMessage') {
              alert('Deleted connection')
            }
            console.log({ res })
            // remove edge from graph
            setGraph({ ...graph, edges: graph.edges.filter((e) => e.id !== connection.id) })

            closeModal()
          }}
        >
          delete connection
        </ConceptNavButton>
        {/* <ConceptNavButton>modify type</ConceptNavButton> */}
      </div>
    </ConnectionModalStyled>
  )
}
