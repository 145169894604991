import React from 'react'
import { useField, useFormikContext, getIn } from 'formik'
import AsyncSelect from 'react-select/async-creatable'
import { components } from 'react-select'

import { SearchIcon } from '../styles/shared/IconsStyled'
import { modalStyles } from '../styles/SearchSelectStyles'

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <SearchIcon size={20} />
  </components.DropdownIndicator>
)

const SearchSelectInput = ({ onChange, loadOptions, ...rest }) => {
  const { setFieldValue, setFieldTouched } = useFormikContext()
  const [field, meta] = useField({ ...rest })

  return (
    <>
      <AsyncSelect
        {...field}
        {...rest}
        styles={modalStyles}
        components={{ DropdownIndicator }}
        placeholder='search...'
        onChange={async (e) => {
          const { id, name } = await onChange(e)
          setFieldValue(rest.name, { value: id, label: name, id, name })
        }}
        onBlur={() => {
          setFieldTouched(rest.name, true)
        }}
        loadOptions={loadOptions}
      />
      {meta.touched && meta.error ? <div style={{ padding: '5px' }}>{getIn(meta.error, field.name)}</div> : null}
      {/* next line deals with react-select nested error for value */}
      {meta.touched && meta.error && meta.error.value ? <div style={{ padding: '5px' }}>{meta.error.value}</div> : null}
    </>
  )
}

export default SearchSelectInput
