import React from 'react'
import { NavigationBar } from '../NavigationBar'

import { Container, MainSection, Footer } from '../styles/shared/ContainersStyled'

const WelcomePage = () => (
  <Container>
    <NavigationBar />
    <MainSection>
      <img height={150} src='https://static.noosphaera.com/noosphaera_logo_300.png' alt='' />
      <span
        style={{
          fontSize: '2.5em',
          color: 'white',
          fontFamily: 'Hind, sans-serif',
          alignContent: 'center'
        }}
      >
        noosphaera
      </span>
      <p
        style={{
          color: 'white',
          fontSize: '1.2em',
          fontFamily: 'Inter',
          fontWeight: 200,
          textAlign: 'center'
        }}
      >
        Let's make the internet work the way it should.
      </p>
    </MainSection>
    <Footer year />
  </Container>
)

export default WelcomePage
