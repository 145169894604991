import React from 'react'
import { useMutation } from '@apollo/client'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { NavigationBar } from '../NavigationBar'

import { GenericTextInput } from '../inputs/TextInput'

import { Container, Footer } from '../styles/shared/ContainersStyled'
import { LowercaseFormTitle } from '../styles/shared/TitlesStyled'
import { CircleSpinner } from 'react-spinners-kit'
import { PrimaryButton } from '../styles/shared/ButtonsStyled'

import FORGOT_PASSWORD from '../../mutations/ForgotPassword'

const LogInPage = () => {
  const [forgotPassword, forgotPasswordData] = useMutation(FORGOT_PASSWORD)

  return (
    <Container>
      <NavigationBar />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundColor: 'white',
          height: 'auto',
          width: '275px'
        }}
      >
        <LowercaseFormTitle>forgot password</LowercaseFormTitle>
        <Formik
          initialValues={{
            email: ''
          }}
          validationSchema={Yup.object({
            email: Yup.string().email('Invalid email address').required('^ Required')
          })}
          onSubmit={async (variables, { setSubmitting }) => {
            setSubmitting(true)
            await forgotPassword({ variables })
            setSubmitting(false)
          }}
        >
          {(formikProps) => (
            <Form style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <div style={{ margin: '0 20px', textAlign: 'center' }}>
                {forgotPasswordData.error && forgotPasswordData.error.message.replace('GraphQL error: ', '')}
                {forgotPasswordData.data &&
                  'We just sent you a password reset link to your email. The link is valid for 24 hours.'}
              </div>

              <GenericTextInput label='email' name='email' type='text' placeholder='email' autoComplete='email' />

              <div style={{ margin: '0 20px 20px 20px', textAlign: 'center' }}>
                <PrimaryButton type='submit' disabled={formikProps.isSubmitting || !formikProps.isValid}>
                  {formikProps.isSubmitting ? <CircleSpinner size={15} loading={true} /> : 'email reset link'}
                </PrimaryButton>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <Footer year />
    </Container>
  )
}

export default LogInPage
