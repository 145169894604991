import { colors } from './shared/Constants'

export const selectStyles = {
  menu: (base) => ({
    ...base,
    borderRadius: 0,
    backgroundColor: 'white',
    boxShadow: '0 1px 2px 0 rgba(60,64,67,.30), 0 2px 6px 2px rgba(60,64,67,.15)',
    margin: '2px 0px'
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#2684ff' : colors.textGray,
    fontSize: '16px',
    textAlign: 'left',
    cursor: 'pointer',
    borderRadius: 0,
    backgroundColor: state.isFocused ? colors.hoverGray : 'white',
    ':focus, :hover, :active, :visited': {
      backgroundColor: state.isFocused ? colors.hoverGray : 'white'
    }
  }),
  container: (base) => ({
    ...base,
    width: '-webkit-fill-available',
    margin: '10px 25px 0 25px'
  }),
  control: (base) => ({
    ...base,
    height: 'auto',
    backgroundColor: colors.hoverGray,
    color: colors.textGray,
    padding: '0 9px',
    border: 'none',
    fontSize: '16px',
    borderRadius: 0,
    width: '100%',
    textAlign: 'left',
    cursor: 'pointer'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: colors.textGray,
    margin: '0',
    fontFamily: 'Inter300, Open Sans, Hind, Arial, sans-serif'
  }),
  dropdownIndicator: (base) => ({ ...base, padding: '0' }),
  indicatorSeparator: (base) => ({ ...base, display: 'none' }),
  valueContainer: (base) => ({ ...base, padding: 0 }),
  input: (base) => ({
    ...base,
    margin: '0',
    padding: '0',
    color: colors.textGray,
    fontSize: '16px',
    fontFamily: 'Inter300, Open Sans, Hind, Arial, sans-serif',
    '& input': {
      font: 'inherit'
    }
  }),
  placeholder: () => ({
    fontSize: '16px'
  })
}

export const modalStyles = {
  menu: (base) => ({
    ...base,
    borderRadius: 0,
    backgroundColor: 'white',
    boxShadow: '0 1px 2px 0 rgba(60,64,67,.30), 0 2px 6px 2px rgba(60,64,67,.15)',
    margin: '2px 0px'
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#2684ff' : colors.textGray,
    fontSize: '1em',
    fontFamily: 'Inter300, Open Sans, Hind, Arial, sans-serif',
    textAlign: 'left',
    cursor: 'pointer',
    borderRadius: 0,
    backgroundColor: state.isFocused ? colors.hoverGray : 'white',
    ':focus, :hover, :active, :visited': {
      backgroundColor: state.isFocused ? colors.hoverGray : 'white'
    }
  }),
  container: (base) => ({
    ...base,
    width: '-webkit-fill-available',
    margin: '10px 25px 0 25px'
  }),
  control: (base) => ({
    ...base,
    height: 'auto',
    backgroundColor: colors.hoverGray,
    color: colors.textGray,
    padding: '0 9px',
    border: 'none',
    fontSize: '16px',
    borderRadius: 0,
    width: '100%',
    textAlign: 'left',
    cursor: 'pointer'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: colors.textGray,
    margin: '0',
    fontFamily: 'Inter300, Open Sans, Hind, Arial, sans-serif'
  }),
  dropdownIndicator: (base) => ({ ...base, padding: '0' }),
  indicatorSeparator: (base) => ({ ...base, display: 'none' }),
  valueContainer: (base) => ({ ...base, padding: 0 }),
  input: (base) => ({
    ...base,
    margin: '0',
    padding: '0',
    color: colors.textGray,
    fontSize: '16px',
    fontFamily: 'Inter300, Open Sans, Hind, Arial, sans-serif',
    '& input': {
      font: 'inherit'
    }
  }),
  placeholder: () => ({
    fontSize: '16px',
    fontFamily: 'Inter300, Open Sans, Hind, Arial, sans-serif'
  })
}
