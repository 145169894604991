import { gql } from '@apollo/client'

export default gql`
  mutation AddConceptPaperInsight($paperDoi: String!, $conceptId: String!, $insight: String!) {
    addConceptPaperInsight(paperDoi: $paperDoi, conceptId: $conceptId, insight: $insight) {
      ... on SuccessMessage {
        message
      }
      ... on GenericError {
        message
      }
    }
  }
`
