import React, { useState, useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { useDropzone } from 'react-dropzone'
import axios from 'axios'

import { UploadContainer } from '../components/styles/shared/ContainersStyled'

import UPLOAD_CONCEPT_VISUAL from '../mutations/UploadConceptVisual'

const UploadConceptVisual = ({ concept, level, closeUpload }) => {
  const [uploadPercentage, setUploadPercentage] = useState(null)
  const [fileIsTooLarge, setFileIsTooLarge] = useState(false)

  const [uploadConceptVisual] = useMutation(UPLOAD_CONCEPT_VISUAL, {
    refetchQueries: ['GetConceptVisuals']
  })

  const onDragEnter = useCallback(() => {
    setFileIsTooLarge(false)
  }, [])

  const onDropAccepted = useCallback(
    async (acceptedFiles) => {
      try {
        const file = acceptedFiles[0]

        const {
          data: { uploadConceptVisual: presignedPost }
        } = await uploadConceptVisual({
          variables: { conceptId: concept.id, difficulty: level, contentType: file.type }
        })

        const { url, fields } = presignedPost

        let formData = new FormData()

        formData.append('Key', fields.key)
        formData.append('bucket', fields.bucket)
        formData.append('X-Amz-Algorithm', fields.XAmzAlgorithm)
        formData.append('X-Amz-Credential', fields.XAmzCredential)
        formData.append('X-Amz-Date', fields.XAmzDate)
        formData.append('Policy', fields.Policy)
        formData.append('X-Amz-Signature', fields.XAmzSignature)
        formData.append('Content-Type', file.type)
        formData.append('file', file)

        await axios.post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: (progressEvent) => {
            setUploadPercentage(parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100)))
          }
        })
        setTimeout(() => closeUpload(), 500)
      } catch (e) {
        console.log(e)
      }
    },
    [uploadConceptVisual, concept.id, level, closeUpload]
  )

  const onDropRejected = useCallback((rejectedFiles) => {
    const file = rejectedFiles[0]

    setFileIsTooLarge(rejectedFiles.length > 0 && file.size > 10000000)
  }, [])

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDragEnter,
    onDropAccepted,
    onDropRejected,
    noDragEventsBubbling: true,
    minSize: 1,
    maxSize: 10000000,
    multiple: false,
    accept: 'image/jpeg, image/png'
  })

  return (
    <UploadContainer {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
      <input {...getInputProps()} />
      <span>Drag 'n' drop an image</span>
      <span>Click to open file dialog</span>
      <span style={{ fontWeight: 'bold' }}> Only PNG and JPEG files accepted</span>
      {isDragReject && <strong style={{ color: 'red' }}>Unsupported File Type</strong>}
      {fileIsTooLarge && <strong style={{ color: 'red' }}>File is larger than 10 MB</strong>}
      {uploadPercentage && <strong style={{ color: 'green' }}>{uploadPercentage}%</strong>}
    </UploadContainer>
  )
}

export default UploadConceptVisual
