import React from 'react'
import { useFormikContext, useField, getIn } from 'formik'
import Select from 'react-select'
import { selectStyles } from '../styles/SelectStyles'

const SelectInput = ({ label, ...props }) => {
  const { setFieldValue, setFieldTouched } = useFormikContext()
  const [field, meta] = useField(props)

  function handleOptionChange(selection) {
    setFieldValue(props.name, selection)
  }

  function updateBlur() {
    setFieldTouched(props.name, true)
  }

  return (
    <>
      <Select styles={selectStyles} {...field} {...props} onBlur={updateBlur} onChange={handleOptionChange} />

      {meta.touched && meta.error ? <div style={{ padding: '5px' }}>{getIn(meta.error, field.name)}</div> : null}
      {/* next line deals with react-select nested error for value */}
      {meta.touched && meta.error && meta.error.value ? <div style={{ padding: '5px' }}>{meta.error.value}</div> : null}
    </>
  )
}

export default SelectInput
