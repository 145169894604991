import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import ParticlesBackground from '../components/styles/shared/ParticlesBackground'
import WelcomePage from '../components/pages/WelcomePage'
import LearnMorePage from '../components/pages/LearnMorePage'
import LogInPage from '../components/pages/LogInPage'
import LogOutPage from '../components/pages/LogOutPage'
import SignUpPage from '../components/pages/SignUpPage'
import ConfirmEmailPage from '../components/pages/ConfirmEmailPage'
import ForgotPasswordPage from '../components/pages/ForgotPasswordPage'
import ResetPasswordPage from '../components/pages/ResetPasswordPage'
import NotFoundPage from '../components/pages/NotFoundPage'
import ExplorePage from '../components/pages/ExplorePage'
import GraphDemo from '../components/pages/GraphDemo'

import { FullPageSpinner } from '../components/styles/shared/ContainersStyled'

import ME from '../queries/Me'

import '../components/styles/GlobalStyle.css'

const AppRouter = () => {
  const me = useQuery(ME, { fetchPolicy: 'network-only' })

  if (me.loading) return <FullPageSpinner />

  if (me.data?.me?.__typename === 'User')
    return (
      <BrowserRouter>
        <Switch>
          {/* Private Routes */}
          <Route path='/explore' component={ExplorePage} />
          <Route path='/logout' component={LogOutPage} />
          {/* Testing */}
          <Route path='/demo' component={GraphDemo} />
          {/* Shared Routes */}
          {/* API Routes */}
          {/* TODO: fix navbar rendering and logged in edge cases */}
          <Route path='/api/confirm-email/:token' component={ConfirmEmailPage} />
          <Route path='/api/reset-password/:token' component={ResetPasswordPage} />
          {/* Fallback Route */}
          <Redirect to='/explore' />
          <Route component={NotFoundPage} />
        </Switch>
      </BrowserRouter>
    )

  return (
    <>
      <BrowserRouter>
        <Switch>
          {/* Public Routes */}
          <Route path='/' exact component={WelcomePage} />
          <Route path='/login' component={LogInPage} />
          <Route path='/signup' component={SignUpPage} />
          <Route path='/learn-more' component={LearnMorePage} />
          <Route path='/forgot-password' component={ForgotPasswordPage} />

          {/* Shared Routes */}
          {/* API Routes */}
          <Route path='/api/confirm-email/:token' component={ConfirmEmailPage} />
          <Route path='/api/reset-password/:token' component={ResetPasswordPage} />
          {/* Fallback Route */}
          <Route component={NotFoundPage} />
        </Switch>
      </BrowserRouter>
      <ParticlesBackground />
    </>
  )
}

export default AppRouter
