import styled from 'styled-components/macro'

export const LowercaseFormTitle = styled.div`
  font-family: 'Inter300', 'Hind';
  font-size: 2.25em;
  color: rgb(101, 107, 117);
  text-align: center;
  margin: 20px 20px 0 20px;
`
export const ConceptTitle = styled.div`
  font-family: 'Inter300';
  font-size: 2em;
  padding-bottom: 10px;

  @media screen and (max-width: 370px) {
    font-size: 1.25em;
  }
  @media screen and (max-width: 520px) {
    font-size: 1.5em;
  }
`
