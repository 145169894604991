import { gql } from '@apollo/client'

export const GET_CONNECTIONS = gql`
  query GetConnections($conceptId: String!, $type: String!) {
    getConnections(conceptId: $conceptId, type: $type) {
      ... on Connection {
        id
        type
        from {
          id
          name
        }
        to {
          id
          name
        }
        concept {
          id
        }
      }
    }
  }
`
