import { gql } from '@apollo/client'

export default gql`
  query GetConceptVisuals($id: String!) {
    getConceptById(id: $id) {
      ... on Concept {
        visuals {
          id
          url
          level
        }
      }
    }
  }
`
