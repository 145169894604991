import React from 'react'
import { useHistory } from 'react-router-dom'
import { NavigationBar } from '../NavigationBar'

import { Container, Footer } from '../styles/shared/ContainersStyled'
import { LowercaseFormTitle } from '../styles/shared/TitlesStyled'
import { PrimaryButton } from '../styles/shared/ButtonsStyled'

const NotFoundPage = () => {
  const history = useHistory()
  return (
    <Container>
      <NavigationBar />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundColor: 'white',
          height: 'auto',
          width: '275px'
        }}
      >
        <LowercaseFormTitle>404, page not found</LowercaseFormTitle>
        <div style={{ margin: '0 20px', textAlign: 'center' }}>Please try another link.</div>
        <div style={{ margin: '0 20px 20px 20px', textAlign: 'center' }}>
          <PrimaryButton
            onClick={() => {
              history.push('/')
            }}
          >
            go home
          </PrimaryButton>
        </div>
      </div>
      <Footer year />
    </Container>
  )
}

export default NotFoundPage
