import { gql } from '@apollo/client'

export const DELETE_CONCEPT_PAPER_INSIGHT = gql`
  mutation DeleteConceptPaperInsight($conceptPaperInsightId: String!) {
    deleteConceptPaperInsight(conceptPaperInsightId: $conceptPaperInsightId) {
      __typename
      ... on SuccessMessage {
        message
      }
      ... on GenericError {
        message
      }
    }
  }
`
