import styled from 'styled-components/macro'
import { Search, Plus } from '@styled-icons/octicons'
import { CloudUpload, ArrowBack, ArrowForward } from '@styled-icons/ionicons-outline'

import { colors } from './Constants'

export const SearchIcon = styled(Search)`
  color: ${colors.primary};
`

export const UploadIcon = styled(CloudUpload)`
  color: white;
`

export const ArrowBackIcon = styled(ArrowBack)`
  color: black;
`

export const ArrowForwardIcon = styled(ArrowForward)`
  color: black;
`

export const PlusIcon = styled(Plus)`
  color: white;
  vertical-align: bottom;
  height: 14px;
  width: 14px;

  @media screen and (max-width: 260px) {
    height: 14px;
    width: 14px;
  }
  @media screen and (min-width: 370px) {
    height: 14px;
    width: 14px;
  }
  @media screen and (min-width: 520px) {
    height: 15px;
    width: 15px;
  }
  @media screen and (min-width: 700px) {
    height: 17px;
    width: 17px;
  }
  @media screen and (min-width: 800px) {
    height: 18px;
    width: 18px;
  }
  @media screen and (min-width: 900px) {
    height: 19px;
    width: 19px;
  }
`
