import { gql } from '@apollo/client'

export default gql`
  mutation UploadConceptVisual($conceptId: String!, $difficulty: String!, $contentType: String!) {
    uploadConceptVisual(conceptId: $conceptId, difficulty: $difficulty, contentType: $contentType) {
      __typename
      ... on PresignedPostData {
        url
        fields {
          key
          bucket
          XAmzAlgorithm
          XAmzCredential
          XAmzDate
          Policy
          XAmzSignature
        }
      }
      ... on GenericError {
        message
      }
    }
  }
`
