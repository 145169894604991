import { gql } from '@apollo/client'

export default gql`
  query GetConceptPaperInsights($conceptId: String!, $paperDoi: String!) {
    getConceptPaperInsights(conceptId: $conceptId, paperDoi: $paperDoi) {
      __typename
      ... on ConceptPaperInsight {
        id
        insight
      }
      ... on GenericError {
        message
      }
    }
  }
`
