import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import axios from 'axios'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { useConceptModalStore } from '../stores/useConceptModalStore'

import { References } from './References'
import { Tooltip } from './Tooltip'
import { PaperDetails } from './PaperDetails'
import { GenericTextInput } from './inputs/TextInput'

import { ConceptNavButton } from './styles/shared/ButtonsStyled'
import { CircleSpinner } from 'react-spinners-kit'

import GET_CONCEPT_PAPERS from '../queries/GetConceptPapers'
import ADD_CONCEPT_PAPER from '../mutations/AddConceptPaper'

export const DiscoveryTab = () => {
  const [addPaperMessage, setAddPaperMessage] = useState('')
  const [showInputAddPaper, setShowInputAddPaper] = useConceptModalStore((state) => [
    state.showInputPaperInsight,
    state.setShowInputAddPaper
  ])
  const activeConcept = useConceptModalStore((state) => state.activeConcept)
  const [referencesSortOrder, setReferencesSortOrder] = useConceptModalStore((state) => [
    state.referencesSortOrder,
    state.setReferencesSortOrder
  ])

  const [addConceptPaper] = useMutation(ADD_CONCEPT_PAPER, {
    fetchPolicy: 'no-cache',
    refetchQueries: ['GetConceptPapers'],
    awaitRefetchQueries: true
  })
  const { data, loading } = useQuery(GET_CONCEPT_PAPERS, {
    variables: { conceptId: activeConcept.id },
    fetchPolicy: 'no-cache'
  })

  const fetchDoi = async (doiUrl) => {
    // const doiRe = /\b10\.[0-9]{4,}\/[^\s&"']*[^\s&"'.,]/g
    const doi = doiUrl.split('org/')[1]
    console.log('doi: ', doi)
    // https://scholar.google.com/scholar?hl=en&as_sdt=1%2C5&q=10.1146/annurev-neuro-090919-022842
    console.time('apis')
    // let title
    let references
    let citations
    let referencesCount
    let citationsCount
    // try {
    //   const doiLookup = await axios.get(doiUrl, { validateStatus: () => true })
    //   console.log('doiLookup.status: ', doiLookup?.status)
    // } catch (e) {
    //   console.log(e.status)
    // }
    const crossref = await axios.get(`https://api.crossref.org/works/${doi}`, { validateStatus: () => true })
    console.log('crossref: ', crossref)
    console.log('crossref.status: ', crossref?.status)
    if (crossref?.status === 200) {
      console.log('crossref.data.message: ', crossref?.data?.message)
      citationsCount = crossref?.data?.message['is-referenced-by-count']
      referencesCount = crossref?.data?.message['reference-count']
      references = crossref?.data?.message?.reference?.map((r) => ({
        doi: r.DOI ? r.DOI : null,
        unstructured: r.unstructured ? r.unstructured : null
      }))
      console.log('citationsCount: ', citationsCount)
      console.log('referencesCount: ', referencesCount)
      console.log('references: ', references)
    }
    const opencitationsCitations = await axios.get(`https://w3id.org/oc/index/api/v1/citations/${doi}`, {
      validateStatus: () => true
    })
    console.log('opencitationsCitations: ', opencitationsCitations)
    if (opencitationsCitations?.status === 200) {
      const citingDois = opencitationsCitations.data.map((p) => p.citing.match(/=> (\S*)/)[1])
      citations = citingDois
      // console.log('citingDois: ', citingDois)
      console.log('citations.length: ', citations.length)
      // console.log('opencitations: ', opencitations)
    }
    const opencitationsMetadata = await axios.get(`https://w3id.org/oc/index/api/v1/metadata/${doi}`, {
      validateStatus: () => true
    })
    if (opencitationsMetadata?.status === 200) {
      console.log('opencitationsMetadata: ', opencitationsMetadata)
      if (!references) {
        references = opencitationsMetadata?.data[0]?.reference
          ?.split(';')
          .map((d) => ({ doi: d.trim(), unstructured: null }))
      }
    }
    console.log('references: ', references)
    // if status ok
    let date = crossref?.data?.message?.['published-print']
      ? crossref?.data?.message['published-print']
      : crossref?.data?.message['created']
    const paper = {
      doi,
      title: crossref?.data?.message?.title[0],
      references,
      referencesCount,
      citations,
      citationsCount,
      firstAuthor: crossref?.data?.message?.author[0],
      year: date['date-parts'][0][0],
      month: date['date-parts'][0][1]
    }
    console.log('paper: ', paper)
    // setActivePaper(paper)
    // console.log('paperComplete: ', paperComplete)
    console.log('done')
    console.timeEnd('apis')
    return paper
  }

  return (
    <div style={{ overflow: 'scroll', borderTop: '10px solid white' }}>
      {/* Paper Timeline */}
      <div>
        {showInputAddPaper && (
          <div>
            <div>add paper</div>
            <Formik
              initialValues={{
                doiUrl: ''
              }}
              validationSchema={Yup.object({
                doiUrl: Yup.string()
                  .url('^ Enter a valid DOI URL, eg. https://doi.org/10.1146/annurev-neuro-090919-022842')
                  .required('^ Required')
              })}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                setSubmitting(true)
                const res = await addConceptPaper({ variables: { ...values, conceptId: activeConcept.id } })
                setSubmitting(false)

                setAddPaperMessage(res?.data?.addConceptPaper?.message)

                resetForm()
              }}
              validateOnBlur={false}
            >
              {(formikProps) => (
                <Form
                  style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
                >
                  <div style={{ margin: '0 20px 5px 20px', textAlign: 'center' }}>{addPaperMessage}</div>
                  <GenericTextInput
                    style={{ margin: '0px 0px 10px 0px' }}
                    errorStyle={{ marginBottom: '10px' }}
                    label='DOI'
                    name='doiUrl'
                    type='text'
                    placeholder='doi url'
                  />
                  <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <ConceptNavButton
                      type='submit'
                      disabled={formikProps.isSubmitting || !formikProps.isValid}
                      style={{ padding: '8px 12px' }}
                    >
                      {formikProps.isSubmitting ? <CircleSpinner size={15} loading={true} /> : 'add paper'}
                    </ConceptNavButton>
                    <ConceptNavButton
                      type='button'
                      style={{ marginLeft: '10px', padding: '8px 12px' }}
                      onClick={(e) => {
                        e.preventDefault()
                        setShowInputAddPaper(false)
                        setAddPaperMessage('')
                      }}
                    >
                      close + paper
                    </ConceptNavButton>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            margin: '8px 0px',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Tooltip text='Select a paper to see insights and statistics...'>
            <div>Paper Timeline</div>
          </Tooltip>
          <ConceptNavButton
            style={{ padding: '8px 12px', visibility: showInputAddPaper ? 'hidden' : 'visible' }}
            onClick={() => {
              setShowInputAddPaper(true)
            }}
          >
            + paper
          </ConceptNavButton>
        </div>
        {!loading && data && (
          <References
            referenceCount={data.getConceptPapers.length}
            referenceDois={
              data.getConceptPapers.map((paper) => paper.doi).join('__')
              // activePaper
              //   ? 'none'
              //   : // ? activePaper?.references
              //     //     .map((r) => r.doi)
              //     //     .filter((r) => r !== null)
              //     //     .join('__')
              //     'none'
            }
          />
        )}
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: '8px' }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '5px' }}>
            sort by
          </div>
          <ConceptNavButton
            active={referencesSortOrder === 'citations'}
            onClick={() => {
              setReferencesSortOrder('citations')
            }}
            style={{ padding: '8px 12px' }}
          >
            citations
          </ConceptNavButton>
          <ConceptNavButton
            active={referencesSortOrder === 'year'}
            onClick={() => {
              setReferencesSortOrder('year')
            }}
            style={{ padding: '8px 12px' }}
          >
            year
          </ConceptNavButton>
        </div>
      </div>
      <PaperDetails />
    </div>
  )
}
