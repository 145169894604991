import { gql } from '@apollo/client'

export const DELETE_CONNECTION = gql`
  mutation DeleteConnection($connectionId: String!) {
    deleteConnection(connectionId: $connectionId) {
      __typename
      ... on GenericError {
        message
      }
      ... on SuccessMessage {
        message
      }
    }
  }
`
