import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import { FullPageSpinner } from '../styles/shared/ContainersStyled'

import LOG_OUT from '../../mutations/LogOut'

const LogOutPage = () => {
  const history = useHistory()
  const [logOut] = useMutation(LOG_OUT)

  useEffect(() => {
    const executeLogOut = async () => {
      await logOut()
      history.push('/')
      window.location.reload()
    }

    executeLogOut()
  })

  return <FullPageSpinner />
}

export default LogOutPage
