import React, { cloneElement } from 'react'
import { useLayer, useHover, Arrow } from 'react-laag'
import { motion, AnimatePresence } from 'framer-motion'

export const Tooltip = ({ children, text }) => {
  // We use `useHover()` to determine whether we should show the tooltip.
  // Notice how we're configuring a small delay on enter / leave.
  const [isOver, hoverProps] = useHover({ delayEnter: 100, delayLeave: 300 })

  // Tell `useLayer()` how we would like to position our tooltip
  const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
    isOpen: isOver,
    auto: true,
    triggerOffset: 8 // small gap between wrapped content and the tooltip
  })

  // when children equals text (string | number), we need to wrap it in an
  // extra span-element in order to attach props
  let trigger
  if (isReactText(children)) {
    trigger = (
      <span {...triggerProps} {...hoverProps}>
        {children}
      </span>
    )
  } else {
    // In case of an react-element, we need to clone it in order to attach our own props
    trigger = cloneElement(children, {
      ...triggerProps,
      ...hoverProps
    })
  }

  // We're using framer-motion for our enter / exit animations.
  // This is why we need to wrap our actual tooltip inside `<AnimatePresence />`.
  // The only thing left is to describe which styles we would like to animate.
  return (
    <>
      {trigger}
      {renderLayer(
        <AnimatePresence>
          {isOver && (
            <motion.div
              initial={{
                opacity: 0,
                scale: 0.9,
                backgroundColor: 'rgba(0, 0, 0, 0.75)',
                color: 'white',
                padding: '6px 12px',
                transformOrigin: 'center center',
                pointerEvents: 'none',
                whiteSpace: 'wrap',
                maxWidth: '300px'
              }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              transition={{ duration: 0.1 }}
              {...layerProps}
              style={{ ...layerProps.style, width: '300px' }}
            >
              {text}
              <Arrow {...arrowProps} backgroundColor='rgba(0, 0, 0, 0.75)' size={6} />
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </>
  )
}

function isReactText(children) {
  return ['string', 'number'].includes(typeof children)
}
