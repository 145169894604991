import { gql } from '@apollo/client'

export default gql`
  mutation LogIn($email: String!, $password: String!) {
    logIn(email: $email, password: $password) {
      __typename
      ... on User {
        id
      }
      ... on GenericError {
        message
      }
    }
  }
`
