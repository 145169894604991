import React from 'react'
import { useField } from 'formik'

import { GenericTextInputStyle, GenericTextAreaInputStyle } from '../styles/shared/InputsStyled'

const TextInput = ({ styled: Component, label, errorStyle, ...props }) => {
  const [field, meta] = useField(props)

  if (Component) {
    return (
      <>
        <Component {...field} {...props} />
        {meta.touched && meta.error ? (
          <div style={errorStyle ? errorStyle : { paddingTop: '9px', margin: '0px 25px', textAlign: 'center' }}>
            {meta.error}
          </div>
        ) : null}
      </>
    )
  }

  return (
    <>
      <input {...field} {...props} />
      {meta.touched && meta.error ? (
        <div style={errorStyle ? errorStyle : { paddingTop: '9px', margin: '0px 25px', textAlign: 'center' }}>
          {meta.error}
        </div>
      ) : null}
    </>
  )
}

export const GenericTextInput = (props) => <TextInput styled={GenericTextInputStyle} {...props} />

const TextAreaInput = ({ styled: Component, label, errorStyle, ...props }) => {
  const [field, meta] = useField(props)

  if (Component) {
    return (
      <>
        <Component {...field} {...props} />
        {meta.touched && meta.error ? (
          <div style={errorStyle ? errorStyle : { paddingTop: '9px', margin: '0px 25px', textAlign: 'center' }}>
            {meta.error}
          </div>
        ) : null}
      </>
    )
  }

  return (
    <>
      <textarea {...field} {...props} />
      {meta.touched && meta.error ? (
        <div style={errorStyle ? errorStyle : { paddingTop: '9px', margin: '0px 25px', textAlign: 'center' }}>
          {meta.error}
        </div>
      ) : null}
    </>
  )
}

export const GenericTextAreaInput = (props) => <TextAreaInput styled={GenericTextAreaInputStyle} {...props} />

export default TextInput
