export const setUpNetwork = (network) => {
  // min, max, and initialScale depend on device width
  const width = window.innerWidth
  let min, max, initialScale
  min = 0.5
  if (width <= 380) {
    initialScale = 0.6
    max = 0.8
  } else if (width <= 500) {
    initialScale = 0.7
    max = 1
  } else if (width <= 650) {
    initialScale = 0.8
    max = 1.3
  } else {
    initialScale = 0.9
  }
  max = 1.5
  // Set initial scale
  network.moveTo({
    position: { x: 0, y: 0 },
    scale: initialScale
  })
  // Add zooming out and in limit
  network.on('zoom', () => {
    if (network.getScale() <= min) {
      network.moveTo({
        position: { x: 0, y: 0 },
        scale: min
      })
    }
    if (network.getScale() >= max) {
      network.moveTo({
        position: { x: 0, y: 0 },
        scale: max
      })
    }
  })

  // Add cursor changes
  // Get the canvas HTML element
  let networkCanvas = document.querySelector('.vis-network > canvas')

  const changeCursor = (newCursorStyle) => {
    networkCanvas.style.cursor = newCursorStyle
  }

  network.on('hoverNode', () => {
    changeCursor('pointer')
  })
  network.on('blurNode', () => {
    changeCursor('default')
  })
  network.on('hoverEdge', () => {
    changeCursor('pointer')
  })
  network.on('blurEdge', () => {
    changeCursor('default')
  })
  network.on('dragStart', () => {
    changeCursor('grabbing')
  })
  network.on('dragging', () => {
    changeCursor('grabbing')
  })
  network.on('dragEnd', () => {
    changeCursor('grab')
  })
}

export const options = {
  nodes: {
    borderWidth: 1.8,
    borderWidthSelected: 2,
    color: {
      border: 'rgba(255,255,255,0)'
      // highlight: {
      //   border: 'rgb(255, 120, 120)',
      //   background: 'rgb(255, 190, 190)'
      // }
      // hover: {
      //   border: colors.primary,
      //   background: '#52b9b5'
      // }
    },
    font: {
      face: 'Inter300',
      size: 18,
      color: 'black'
    },
    labelHighlightBold: false,
    margin: 7,
    shape: 'circle',
    widthConstraint: {
      minimum: 30,
      maximum: 110
    }
  },
  edges: {
    color: {
      color: 'black'
    },
    width: 2,
    arrows: {
      to: {
        enabled: true,
        scaleFactor: 0.75,
        type: 'arrow'
      }
    },
    font: {
      face: 'Inter300',
      size: 16,
      strokeWidth: 0,
      align: 'top'
    },
    scaling: {
      label: true
    },
    smooth: { type: 'discrete' }
  },
  interaction: {
    hover: true,
    hoverConnectedEdges: false,
    selectConnectedEdges: false,
    hideEdgesOnDrag: true
  },
  physics: {
    barnesHut: {
      avoidOverlap: 0.7,
      springLength: 160
    }
  },
  autoResize: true,
  height: '100%',
  width: '100%'
}
