import React, { useState, useEffect } from 'react'
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
import { Formik, Form } from 'formik'
import Select from 'react-select'
import * as Yup from 'yup'

import { useConceptModalStore } from '../stores/useConceptModalStore'

import SelectInput from './inputs/SelectInput'
import SearchSelectInput from './inputs/SearchSelectInput'

import { ConceptNavBar } from './styles/shared/NavBarsStyled'
import { connectionTypeSelectStyles } from './styles/ConnectionTypeSelectStyles'
import { modalStyles as modalSelectStyles } from './styles/SelectStyles'
import { modalStyles as modalSearchStyles } from './styles/SearchSelectStyles'
import { ConnectionsNavButton, PrimaryButton } from './styles/shared/ButtonsStyled'
import { ConceptLink } from './styles/shared/LinksStyled'
import { CircleSpinner } from 'react-spinners-kit'
import { toTitleCase } from './utils/util'

import CREATE_CONNECTION from '../mutations/CreateConnection'
import SEARCH_CONCEPTS from '../queries/SearchConcepts'
import CREATE_CONCEPT from '../mutations/CreateConcept'
import GET_CONCEPT_CONNECTIONS from '../queries/GetConceptConnections'

export const ConnectionsTab = ({ concept }) => {
  const [showCreateConnection, setShowCreateConnection] = useState(false)
  const [searchDebounce, setSearchDebounce] = useState({})
  const [connectionType, setConnectionType] = useState({ value: 'components', label: 'components' })

  const setActiveConcept = useConceptModalStore((state) => state.setActiveConcept)

  const { loading, data, refetch: getConceptConnections } = useQuery(GET_CONCEPT_CONNECTIONS, {
    variables: { conceptId: concept.id, type: connectionType.value }
  })
  const [createConnection] = useMutation(CREATE_CONNECTION)
  const [searchConcepts] = useLazyQuery(SEARCH_CONCEPTS)
  const [createConcept] = useMutation(CREATE_CONCEPT)

  // searchDebounce handler
  useEffect(() => {
    const { callback, delay } = searchDebounce
    if (callback) {
      const timeout = setTimeout(callback, delay)
      return () => clearTimeout(timeout)
    }
  }, [searchDebounce])

  const formatCreateLabel = (inputValue) => `create concept "${toTitleCase(inputValue)}"`

  const loadOptions = (query, reactSelectCallback) => {
    // NOTE: Implementation from https://gitmemory.com/issue/JedWatson/react-select/614/621579510
    setSearchDebounce({
      callback: async () => {
        const res = await searchConcepts({ variables: { query } })
        // console.log('res: ', res)

        const conceptOptions = res.data.searchConcepts.map((concept) => ({
          ...concept,
          value: concept.id,
          label: concept.name
        }))
        reactSelectCallback(conceptOptions)
      },
      delay: 150
    })
  }

  return (
    <>
      {!showCreateConnection && (
        <div>
          <div>Existing connections for {concept.label} here:</div>
          <div style={{ fontWeight: '400' }}>
            {loading ? (
              <CircleSpinner size={40} loading={true} />
            ) : (
              data?.getConceptConnections.map(({ id, name }, index) => (
                <ConceptLink
                  key={index}
                  onClick={() => {
                    setActiveConcept({ id, label: name })
                  }}
                >
                  {name}
                </ConceptLink>
              ))
            )}
          </div>
        </div>
      )}
      {showCreateConnection && (
        <div>
          <Formik
            initialValues={{
              toId: { label: 'search...', value: 'search...' },
              type: { label: 'component', value: 'component' }
            }}
            validationSchema={Yup.object({
              toId: Yup.object().shape({
                label: Yup.string().notOneOf(['search...']).required('^ Required'),
                value: Yup.string().notOneOf(['search...'], '^ Required').required('^ Required')
              }),
              type: Yup.object().shape({
                value: Yup.string()
                  .oneOf(
                    ['component', 'component of', 'analogy', 'application', 'application of'],
                    '^ Invalid Connection Type'
                  )
                  .required('^ Required')
              })
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true)
              await createConnection({
                variables: { fromId: concept.id, toId: values.toId.value, type: values.type.value }
              })
              setSubmitting(false)
              resetForm()
            }}
          >
            {(formikProps) => (
              <Form style={{ display: 'flex', width: '275px', flexDirection: 'column', alignItems: 'center' }}>
                {/* Error Block */}
                {/* {/* <div style={{ margin: '0 20px', textAlign: 'center' }}>
                  {betaSignUpData.data &&
                    betaSignUpData.data.betaSignUp === true &&
                    'Thank you for applying for Beta. Expect an email in the next few days.'}
                  {betaSignUpData.error && betaSignUpData.error.message.replace('GraphQL error: ', '')}
                </div> */}
                <div style={{ marginTop: '10px' }}>from: {concept.label}</div>
                <SelectInput
                  label='Type'
                  name='type'
                  type='text'
                  placeholder='type'
                  styles={modalSelectStyles}
                  options={['component', 'component of', 'analogy', 'application', 'application of'].map((type) => ({
                    value: type,
                    label: type
                  }))}
                />
                <div style={{ marginTop: '10px' }}>to:</div>
                <SearchSelectInput
                  label='toId'
                  name='toId'
                  type='text'
                  styles={modalSearchStyles}
                  loadOptions={loadOptions}
                  formatCreateLabel={formatCreateLabel}
                  onChange={async (e) => {
                    if (e.__isNew__) {
                      const {
                        data: {
                          createConcept: { id, name }
                        }
                      } = await createConcept({ variables: { name: toTitleCase(e.value) } })

                      return { value: id, label: name, id, name }
                    }

                    return e
                  }}
                />
                <div style={{ margin: '0 20px 20px 20px', textAlign: 'center' }}>
                  <PrimaryButton type='submit' disabled={formikProps.isSubmitting || !formikProps.isValid}>
                    {formikProps.isSubmitting ? <CircleSpinner size={15} loading={true} /> : 'create connection'}
                  </PrimaryButton>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
      <ConceptNavBar>
        {!showCreateConnection && (
          <Select
            styles={connectionTypeSelectStyles}
            options={[
              { value: 'components', label: 'components' },
              { value: 'componentOf', label: 'component of' },
              { value: 'analogies', label: 'analogies' },
              { value: 'applications', label: 'applications' },
              { value: 'applicationOf', label: 'application of' }
            ].map(({ value, label }) => ({
              value,
              label
            }))}
            value={connectionType}
            onChange={async (e) => {
              setConnectionType(e)
              await getConceptConnections({ conceptId: concept.id, type: e.value })
            }}
            isSearchable={false}
            menuPlacement='auto'
            maxMenuHeight={120}
            menuPortalTarget={document.body}
          />
        )}

        <ConnectionsNavButton
          style={{ marginLeft: !showCreateConnection ? '10px' : '0px' }}
          onClick={() => {
            setShowCreateConnection(!showCreateConnection)
          }}
        >
          {!showCreateConnection ? 'create connection' : 'close + connection'}
        </ConnectionsNavButton>
      </ConceptNavBar>
    </>
  )
}
