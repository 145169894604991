import { darken } from 'polished'
import { colors } from './shared/Constants'

export const fontSizeTheme = {
  fontSize: '0.9em',
  // '@media screen and (max-width: 260px)': {
  //   fontSize: '0.85em'
  // },
  // '@media screen and (min-width: 370px)': {
  //   fontSize: '0.85em'
  // },
  // '@media screen and (min-width: 520px)': {
  //   fontSize: '0.85em'
  // },
  '@media screen and (min-width: 700px)': {
    fontSize: '0.9em'
  },
  '@media screen and (min-width: 800px)': {
    fontSize: '0.95em'
  },
  '@media screen and (min-width: 900px)': {
    fontSize: '1em'
  }
}

export const heightTheme = {
  '@media screen and (min-width: 260px)': {
    height: '32px'
  },
  '@media screen and (min-width: 370px)': {
    height: '32px'
  },
  '@media screen and (min-width: 520px)': {
    height: 'auto'
  }
  // '@media screen and (min-width: 700px)': {
  //   fontSize: '0.9em'
  // },
  // '@media screen and (min-width: 800px)': {
  //   fontSize: '0.95em'
  // },
  // '@media screen and (min-width: 900px)': {
  //   fontSize: '1em'
  // }
}

export const connectionTypeSelectStyles = {
  menu: (base) => ({
    ...base,
    borderRadius: 0,
    backgroundColor: colors.primary,
    boxShadow: '0 1px 2px 0 rgba(60,64,67,.30), 0 2px 6px 2px rgba(60,64,67,.15)',
    margin: '0px',
    zIndex: 5
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 5
  }),
  option: (provided, state) => ({
    ...provided,
    color: 'white',
    ...heightTheme,
    fontSize: '1em',
    ...fontSizeTheme,
    fontFamily: 'Inter300, Open Sans, Hind, Arial, sans-serif',
    fontWeight: '400',
    textAlign: 'left',
    cursor: 'pointer',
    borderRadius: 0,
    backgroundColor: state.isFocused || state.isSelected ? darken(0.05, colors.primary) : colors.primary,
    ':focus, :hover, :active, :visited': {
      backgroundColor: state.isFocused ? darken(0.05, colors.primary) : colors.primary
    }
  }),
  container: (base) => ({
    ...base,
    display: 'flex',
    width: '160px',
    margin: '0px'
  }),
  control: (base) => ({
    ...base,
    height: 'auto',
    ...heightTheme,
    backgroundColor: colors.primary,
    color: 'white',
    padding: '0 9px',
    border: 'none',
    fontSize: '1em',
    ...fontSizeTheme,
    borderRadius: 0,
    width: '100%',
    textAlign: 'left',
    cursor: 'pointer',
    boxShadow: 'none',
    ':hover': {
      border: 'none'
    }
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: 'white',
    margin: '0',
    fontSize: '1em',
    ...fontSizeTheme,
    fontFamily: 'Inter300, Open Sans, Hind, Arial, sans-serif',
    fontWeight: '400'
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: '0',
    color: 'white',
    ':focus, :hover, :active, :visited': {
      color: 'white'
    }
  }),
  indicatorSeparator: (base) => ({ ...base, display: 'none' }),
  valueContainer: (base) => ({ ...base, padding: 0 }),
  input: (base) => ({
    ...base,
    margin: '0',
    padding: '0',
    color: 'white',
    fontSize: '1em',
    ...fontSizeTheme,
    fontFamily: 'Inter300, Open Sans, Hind, Arial, sans-serif',
    fontWeight: '400',
    '& input': {
      font: 'inherit'
    }
  }),
  placeholder: () => ({
    fontSize: '1em',
    ...fontSizeTheme,
    fontFamily: 'Inter300, Open Sans, Hind, Arial, sans-serif',
    fontWeight: '400'
  })
}
