import { gql } from '@apollo/client'

export default gql`
  query GetConceptConnections($conceptId: String!, $type: String!) {
    getConceptConnections(conceptId: $conceptId, type: $type) {
      ... on Concept {
        id
        name
      }
    }
  }
`
