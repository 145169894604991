import { gql } from '@apollo/client'

export const DELETE_CONCEPT_PAPER = gql`
  mutation DeleteConceptPaper($paperDoi: String!, $conceptId: String!) {
    deleteConceptPaper(paperDoi: $paperDoi, conceptId: $conceptId) {
      __typename
      ... on SuccessMessage {
        message
      }
      ... on GenericError {
        message
      }
    }
  }
`
