import { colors } from './shared/Constants'

export const navBarStyles = {
  menu: (base) => ({
    ...base,
    borderRadius: 0,
    backgroundColor: 'white',
    boxShadow: '0 1px 2px 0 rgba(60,64,67,.30), 0 2px 6px 2px rgba(60,64,67,.15)',
    width: '200px',
    '@media screen and (max-width:370px)': {
      width: '150px'
    },
    margin: '1px 0px'
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#2684ff' : colors.textGray,
    fontSize: '1em',
    textAlign: 'left',
    cursor: 'pointer',
    borderRadius: 0,
    backgroundColor: state.isFocused ? colors.hoverGray : 'white',
    ':focus, :hover, :active, :visited': {
      backgroundColor: state.isFocused ? colors.hoverGray : 'white'
    }
  }),
  container: (base) => ({
    ...base,
    width: '-webkit-fill-available',
    margin: '0px',
    backgroundColor: 'white',
    ':hover': {
      backgroundColor: colors.hoverGray
    }
  }),
  control: (base) => ({
    ...base,
    height: 30,
    backgroundColor: colors.hoverGray,
    color: colors.textGray,
    padding: '0 9px',
    border: 'none',
    minHeight: 30,
    fontSize: 16,
    borderRadius: 0,
    width: '200px',
    textAlign: 'left',
    cursor: 'pointer',
    '@media screen and (max-width:370px)': {
      width: '150px'
    }
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: colors.textGray,
    margin: '0',
    fontFamily: 'Inter300, Hind, Helvetica Neue, Arial, sans-serif',
    ':active': {
      width: '200px'
    }
  }),
  dropdownIndicator: (base) => ({ ...base, padding: '0' }),
  indicatorSeparator: (base) => ({ ...base, display: 'none' }),
  valueContainer: (base) => ({
    ...base,
    padding: 0
  }),
  input: (base) => ({
    ...base,
    margin: '0',
    width: '0px',
    color: colors.textGray,
    fontSize: '1em',
    fontFamily: 'Inter300, Hind, Helvetica Neue, Arial, sans-serif',
    fontWeight: '200',
    '& input': {
      font: 'inherit'
    }
  }),
  placeholder: (base) => ({
    ...base,
    margin: 0
  })
}

export const modalStyles = {
  menu: (base) => ({
    ...base,
    borderRadius: 0,
    backgroundColor: 'white',
    boxShadow: '0 1px 2px 0 rgba(60,64,67,.30), 0 2px 6px 2px rgba(60,64,67,.15)',
    margin: '2px 0px'
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#2684ff' : colors.textGray,
    fontSize: '1em',
    fontFamily: 'Inter300, Open Sans, Hind, Arial, sans-serif',
    fontWeight: '400',
    textAlign: 'left',
    cursor: 'pointer',
    borderRadius: 0,
    backgroundColor: state.isFocused ? colors.hoverGray : 'white',
    ':focus, :hover, :active, :visited': {
      backgroundColor: state.isFocused ? colors.hoverGray : 'white'
    }
  }),
  container: (base) => ({
    ...base,
    width: '-webkit-fill-available',
    margin: '10px 25px 0 25px'
  }),
  control: (base) => ({
    ...base,
    height: 'auto',
    backgroundColor: colors.hoverGray,
    color: colors.textGray,
    padding: '0 9px',
    border: 'none',
    fontSize: '1em',
    borderRadius: 0,
    width: '100%',
    textAlign: 'left',
    cursor: 'pointer'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: colors.textGray,
    margin: '0',
    fontFamily: 'Inter300, Open Sans, Hind, Arial, sans-serif',
    fontWeight: '400'
  }),
  dropdownIndicator: (base) => ({ ...base, padding: '0' }),
  indicatorSeparator: (base) => ({ ...base, display: 'none' }),
  valueContainer: (base) => ({ ...base, padding: 0 }),
  input: (base) => ({
    ...base,
    margin: '0',
    padding: '0',
    width: '0px',
    color: colors.textGray,
    fontSize: '1em',
    fontFamily: 'Inter300, Open Sans, Hind, Arial, sans-serif',
    fontWeight: '400',
    '& input': {
      font: 'inherit'
    }
  }),
  placeholder: (base) => ({
    ...base,
    margin: 0,
    color: colors.textGray,
    fontSize: '1em',
    fontFamily: 'Inter300, Open Sans, Hind, Arial, sans-serif',
    fontWeight: '400'
  })
}
