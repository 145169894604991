import { colors } from '../styles/shared/Constants'

const nodeColors = {
  brandTeal: colors.primary,
  midGreen: 'rgb(87, 185, 157)',
  brightGreen: 'rgb(101, 200, 122)',
  yellow: 'rgb(248, 212, 81)',
  orange: 'rgb(232, 159, 60)',
  blue: 'rgb(79, 152, 213)',
  pink: 'rgb(220, 85, 146)',
  white: 'white',
  purple: 'mediumpurple'
}

export const getRandomNodeColor = () => {
  const colors = Object.values(nodeColors)
  return colors[Math.floor(Math.random() * colors.length)]
}
