import styled from 'styled-components/macro'

export const GenericTextInputStyle = styled.input`
  margin: 10px 25px 0px 25px; /* margins are a bit hardcoded */
  padding: 9px;
  background-color: #f2f3f5; /*rgb(232, 232, 232);*/
  border: none;
  border-radius: 0;
  height: auto;
  width: -webkit-fill-available;
  color: #686f7a; /*rgb(150, 150, 150);*/
  font-size: 1em;
  font-family: 'Inter300', 'Open Sans', Hind, Arial, sans-serif;
  font-weight: 300;

  :focus {
    outline: 1px solid #2684ff;
    outline-offset: -1px;
  }
  ::-internal-input-suggested {
    color: #686f7a;
    font-size: 1em;
    opacity: 1; /* Firefox */
  }
  ::placeholder {
    color: #686f7a;
    font-size: 1em;
    opacity: 1; /* Firefox */
  }
  :-ms-input-placeholder {
    color: #686f7a;
    font-size: 1em;
  }
  ::-ms-input-placeholder {
    color: #686f7a;
    font-size: 1em;
  }
`

export const GenericTextAreaInputStyle = styled.textarea`
  margin: 10px 25px 0px 25px; /* margins are a bit hardcoded */
  padding: 9px;
  background-color: #f2f3f5; /*rgb(232, 232, 232);*/
  border: none;
  border-radius: 0;
  height: auto;
  width: -webkit-fill-available;
  color: #686f7a; /*rgb(150, 150, 150);*/
  font-size: 1em;
  font-family: 'Open Sans', Hind, Arial, sans-serif;
  font-weight: 300;
  resize: vertical;

  :focus {
    outline: 1px solid #2684ff;
    outline-offset: -1px;
  }
  ::-internal-input-suggested {
    color: #686f7a;
    font-size: 1em;
    opacity: 1; /* Firefox */
  }
  ::placeholder {
    color: #686f7a;
    font-size: 1em;
    opacity: 1; /* Firefox */
  }
  :-ms-input-placeholder {
    color: #686f7a;
    font-size: 1em;
  }
  ::-ms-input-placeholder {
    color: #686f7a;
    font-size: 1em;
  }
`

export const SearchBar = styled.div`
  width: -webkit-fill-available;
  margin-left: 10px;
  :focus {
    cursor: text;
  }
  /* width: 200px; */
  /* transition: width 0.2s ease-in;
  :hover {
    width: 400px;
  } */
`
