import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { NavigationBar } from '../NavigationBar'

import { GenericTextInput } from '../inputs/TextInput'

import { Container, Footer } from '../styles/shared/ContainersStyled'
import { LowercaseFormTitle } from '../styles/shared/TitlesStyled'
import { CircleSpinner } from 'react-spinners-kit'
import { PrimaryButton } from '../styles/shared/ButtonsStyled'

import RESET_PASSWORD from '../../mutations/ResetPassword'

const ResetPasswordPage = () => {
  const history = useHistory()
  const params = useParams()

  const [resetPassword, resetPasswordData] = useMutation(RESET_PASSWORD)

  return (
    <Container>
      <NavigationBar />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundColor: 'white',
          height: 'auto',
          width: '275px'
        }}
      >
        <LowercaseFormTitle>reset password</LowercaseFormTitle>
        <Formik
          initialValues={{
            newPassword: '',
            confirmPassword: ''
          }}
          validationSchema={Yup.object({
            newPassword: Yup.string().min(8, '^ Password has to be 8 characters or more').required('^ Required'),
            confirmPassword: Yup.string()
              .oneOf([Yup.ref('newPassword'), null], '^ Passwords must match')
              .required('^ Required')
          })}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true)

            const token = params.token
            const password = values.newPassword
            const confirmPassword = values.confirmPassword
            await resetPassword({ variables: { password, confirmPassword, token } })

            setSubmitting(false)
          }}
        >
          {(formikProps) => (
            <Form style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              {/* Show input fields if not called and not loading */}
              {resetPasswordData.called === false && resetPasswordData.loading === false && (
                <>
                  <GenericTextInput
                    label='new password'
                    name='newPassword'
                    type='password'
                    placeholder='new password'
                    autoComplete='new-password'
                  />
                  <GenericTextInput
                    label='confirm password'
                    name='confirmPassword'
                    type='password'
                    placeholder='confirm password'
                    autoComplete='new-password'
                  />
                </>
              )}
              <div style={{ margin: '0 20px', textAlign: 'center' }}>
                {/* display error if any */}
                {resetPasswordData.error && resetPasswordData.error.message.replace('GraphQL error: ', '')}
                {/* success message */}
                {resetPasswordData.data && resetPasswordData.data.resetPassword && (
                  <div>Password successfully reset.</div>
                )}
              </div>
              <div style={{ margin: '0 20px 20px 20px', textAlign: 'center' }}>
                {/* Default button text is 'reset'  */}
                {/* If password was sucessfully reset button changes to go home */}
                {/* If password was not reset button changes to forgot password */}
                {resetPasswordData.data && resetPasswordData.data.resetPassword ? (
                  <PrimaryButton
                    onClick={() => {
                      history.push('/')
                    }}
                  >
                    go home
                  </PrimaryButton>
                ) : resetPasswordData.error && resetPasswordData.called ? (
                  <PrimaryButton
                    onClick={() => {
                      history.push('/forgot-password')
                    }}
                  >
                    forgot password
                  </PrimaryButton>
                ) : (
                  <PrimaryButton type='submit' disabled={formikProps.isSubmitting || !formikProps.isValid}>
                    {formikProps.isSubmitting ? <CircleSpinner size={15} loading={true} /> : 'reset'}
                  </PrimaryButton>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <Footer year />
    </Container>
  )
}

export default ResetPasswordPage
