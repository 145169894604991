import React from 'react'
import { useMutation } from '@apollo/client'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { NavigationBar } from '../NavigationBar'

import { GenericTextInput } from '../inputs/TextInput'

import { Container, Footer } from '../styles/shared/ContainersStyled'
import { LowercaseFormTitle } from '../styles/shared/TitlesStyled'
import { SmallTextLink } from '../styles/shared/LinksStyled'
import { CircleSpinner } from 'react-spinners-kit'
import { PrimaryButton } from '../styles/shared/ButtonsStyled'

import LOG_IN from '../../mutations/LogIn'

const LogInPage = () => {
  const [logIn, logInData] = useMutation(LOG_IN)

  return (
    <Container>
      <NavigationBar />

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundColor: 'white',
          height: 'auto',
          width: '275px'
        }}
      >
        <LowercaseFormTitle>log in</LowercaseFormTitle>
        <Formik
          initialValues={{
            email: '',
            password: ''
          }}
          validationSchema={Yup.object({
            email: Yup.string().email('Invalid email address').required('^ Required'),
            password: Yup.string().required('^ Required')
          })}
          onSubmit={async (variables, { setSubmitting }) => {
            setSubmitting(true)
            const res = await logIn({ variables })
            setSubmitting(false)

            res.data?.logIn?.id && window.location.reload()
          }}
        >
          {(formikProps) => (
            <Form style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <div style={{ margin: '0 20px', textAlign: 'center' }}>
                {logInData.data?.logIn?.__typename === 'GenericError' && logInData.data.logIn.message}
              </div>
              <GenericTextInput label='email' name='email' type='text' placeholder='email' autoComplete='email' />
              <GenericTextInput
                label='password'
                name='password'
                type='password'
                placeholder='password'
                autoComplete='current-password'
              />
              <div style={{ margin: '5px 20px 0px 20px', textAlign: 'center' }}>
                <SmallTextLink to='/forgot-password'>forgot password?</SmallTextLink>
              </div>
              <div style={{ margin: '0 20px 20px 20px', textAlign: 'center' }}>
                <PrimaryButton
                  type='submit'
                  disabled={formikProps.isSubmitting || !formikProps.isValid}
                  style={{ marginTop: '5px' }}
                >
                  {formikProps.isSubmitting ? <CircleSpinner size={15} loading={true} /> : 'log in'}
                </PrimaryButton>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <Footer year />
    </Container>
  )
}

export default LogInPage
