import create from 'zustand'

export const useConceptModalStore = create((set) => ({
  activeConcept: {},
  setActiveConcept: (newActiveConcept) => set(() => ({ activeConcept: newActiveConcept })),
  activeTab: 'model',
  setActiveTab: (newActiveTab) => set(() => ({ activeTab: newActiveTab })),
  showInputAddPaper: false,
  setShowInputAddPaper: (newBooleanValue) => set(() => ({ showInputPaperInsight: newBooleanValue })),
  showInputAddConceptPaperInsight: false,
  setShowInputAddConceptPaperInsight: (newBooleanValue) =>
    set(() => ({ showInputAddConceptPaperInsight: newBooleanValue })),
  activePaper: {},
  setActivePaper: (newActivePaper) => set(() => ({ activePaper: newActivePaper })),
  references: [],
  setReferences: (references) => set(() => ({ references })),
  referencesSortOrder: 'year',
  setReferencesSortOrder: (order) => set(() => ({ referencesSortOrder: order })),
  activePaperNavTab: 'insights',
  setActivePaperNavTab: (newActivePaperNavTab) => set(() => ({ activePaperNavTab: newActivePaperNavTab }))
}))
