import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

export const SmallTextLink = styled(Link)`
  text-decoration: none;
  font-family: 'Inter';
  font-weight: 400;
  font-size: 0.8em;
  color: rgb(100, 100, 100);
  :hover {
    color: rgb(25, 25, 25);
  }
`

export const ConceptLink = styled.div`
  text-decoration: none;

  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`
