import Modal from 'styled-react-modal'
import styled from 'styled-components'

export const ConceptModal = Modal.styled`
  width: 87.5%;
  height: 70%;
  background-color: white;
  display: flex;
  flex-direction: column;
  border: solid 20px white;
  justify-content: space-between;
`

export const ConnectionModal = Modal.styled`
  width: 87.5%;
  height: 70%;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: space-between;
`

export const SettingsModal = Modal.styled`
  width: auto;
  height: auto;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
  align-items: center;
`

export const SpecialModalBackground = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: ${({ height }) => height};
  z-index: 30;
  background-color: rgba(0, 0, 0, 0.5);
`
