import React from 'react'
import { ModelTab } from './ModelTab'
import { ConnectionsTab } from './ConnectionsTab'
import { DiscoveryTab } from './DiscoveryTab'

import { useConceptModalStore } from '../stores/useConceptModalStore'

import { ConceptModal as ConceptModalStyled } from './styles/shared/ModalsStyled'
import { ConceptHeader } from './styles/shared/ContainersStyled'
import { ConceptTitle } from './styles/shared/TitlesStyled'
import { ConceptNavBar } from './styles/shared/NavBarsStyled'
import { ConceptNavButton } from './styles/shared/ButtonsStyled'

export const ConceptModal = ({ isOpen, onBackgroundClick, backgroundProps, concept }) => {
  const [activeTab, setActiveTab] = useConceptModalStore((state) => [state.activeTab, state.setActiveTab])

  return (
    <ConceptModalStyled isOpen={isOpen} onBackgroundClick={onBackgroundClick} backgroundProps={backgroundProps}>
      <ConceptHeader>
        <ConceptTitle>{concept ? concept.label : 'Concept'}</ConceptTitle>

        <ConceptNavBar>
          {['discovery', 'model', 'connections', 'applications', 'questions'].map((tab) => (
            <ConceptNavButton
              key={tab}
              active={tab === activeTab}
              onClick={() => {
                setActiveTab(tab)
              }}
            >
              {tab}
            </ConceptNavButton>
          ))}
        </ConceptNavBar>
      </ConceptHeader>

      {/* NOTE: use display: none to persist component state when browsing tabs */}
      {activeTab === 'discovery' && <DiscoveryTab concept={concept} />}
      {activeTab === 'model' && <ModelTab concept={concept} />}
      {activeTab === 'connections' && <ConnectionsTab concept={concept} />}
      {activeTab === 'applications' && (
        <>
          <div>{activeTab}</div>
          <div>Relevance for production of value</div>
        </>
      )}
      {activeTab === 'questions' && (
        <>
          <div>{activeTab}</div>
          <div>Visual Tutor</div>
        </>
      )}
    </ConceptModalStyled>
  )
}
