import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { NavigationBar } from '../NavigationBar'

import { Container, Footer } from '../styles/shared/ContainersStyled'
import { LowercaseFormTitle } from '../styles/shared/TitlesStyled'
import { CircleSpinner } from 'react-spinners-kit'
import { PrimaryButton } from '../styles/shared/ButtonsStyled'
import { colors } from '../styles/shared/Constants'

import CONFIRM_EMAIL from '../../mutations/ConfirmEmail'

const ConfirmEmailPage = () => {
  const history = useHistory()
  const { token } = useParams()
  const [loading, setLoading] = useState(true)
  const [confirmEmail, confirmEmailData] = useMutation(CONFIRM_EMAIL)

  useEffect(() => {
    const apiCall = async () => {
      try {
        await confirmEmail({ variables: { token } })
        setLoading(false)
      } catch (e) {
        // console.log(e)
      }
      setLoading(false)
    }
    apiCall()
  }, [confirmEmail, token])

  return (
    <Container>
      <NavigationBar />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundColor: 'white',
          height: 'auto',
          width: '275px'
        }}
      >
        <LowercaseFormTitle>confirm email</LowercaseFormTitle>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: '0px 20px 20px 20px',
            justifyContent: 'center',
            textAlign: 'center'
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CircleSpinner size={25} loading={loading} color={colors.primary} />
          </div>

          {confirmEmailData.error && confirmEmailData.error.message.replace('GraphQL error: ', '')}
          {confirmEmailData.data && `Your email ${confirmEmailData.data.confirmEmail.email} has been confirmed.`}

          {!confirmEmailData.loading && (
            <div>
              <PrimaryButton
                onClick={() => {
                  history.push('/')
                }}
              >
                go home
              </PrimaryButton>
            </div>
          )}
        </div>
      </div>
      <Footer year />
    </Container>
  )
}

export default ConfirmEmailPage
