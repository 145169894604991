import { gql } from '@apollo/client'

export default gql`
  mutation AddConceptPaper($doiUrl: String!, $conceptId: String!) {
    addConceptPaper(doiUrl: $doiUrl, conceptId: $conceptId) {
      __typename
      ... on SuccessMessage {
        message
      }
      ... on GenericError {
        message
      }
    }
  }
`
