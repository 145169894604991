import React from 'react'
import { format } from 'date-fns'
import { Tooltip } from './Tooltip'

import { useConceptModalStore } from '../stores/useConceptModalStore'

import { PaperSquareStyled } from './styles/shared/ContainersStyled'

export const PaperSquare = ({
  doi,
  title,
  authors,
  citationCount,
  referenceCount,
  month,
  year,
  percentage,
  notFound
}) => {
  const [activePaper, setActivePaper] = useConceptModalStore((state) => [state.activePaper, state.setActivePaper])

  const getColorForPercentage = (pct) => {
    const percentColors = [
      { pct: 0.0, color: { r: 255, g: 255, b: 255 } }, // rgba(255,255,255,1)
      { pct: 0.1, color: { r: 240, g: 255, b: 244 } }, // rgba(240,255,244,1)
      { pct: 0.2, color: { r: 190, g: 245, b: 228 } }, // rgba(220,255,228,1)
      { pct: 0.3, color: { r: 190, g: 245, b: 203 } }, // rgba(190,245,203,1)
      { pct: 0.4, color: { r: 133, g: 232, b: 157 } }, // rgba(133,232,157,1)
      { pct: 0.5, color: { r: 52, g: 208, b: 88 } }, // rgba(52,208,88,1)
      { pct: 0.6, color: { r: 40, g: 167, b: 69 } }, // rgba(40,167,69,1)
      { pct: 0.7, color: { r: 34, g: 134, b: 58 } }, // rgba(34,134,58,1)
      { pct: 0.8, color: { r: 23, g: 111, b: 44 } }, // rgba(23,111,44,1)
      { pct: 0.9, color: { r: 22, g: 92, b: 38 } }, // rgba(22,92,38,1)
      { pct: 1.0, color: { r: 20, g: 70, b: 32 } } // rgba(20,70,32,1)
    ]

    for (var i = 1; i < percentColors.length - 1; i++) {
      if (pct < percentColors[i].pct) {
        break
      }
    }
    let lower = percentColors[i - 1]
    let upper = percentColors[i]
    let range = upper.pct - lower.pct
    let rangePct = (pct - lower.pct) / range
    let pctLower = 1 - rangePct
    let pctUpper = rangePct
    let color = {
      r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
      g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
      b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
    }
    return 'rgb(' + [color.r, color.g, color.b].join(',') + ')'
  }

  const isSelected = () => activePaper?.doi === doi

  const authorsText = (authors) => {
    // const author = [
    //   {
    //     ORCID: 'http://orcid.org/0000-0001-8211-2855',
    //     'authenticated-orcid': true,
    //     given: 'Leandro M',
    //     family: 'Alonso',
    //     sequence: 'first',
    //     affiliation: [{ name: 'Volen Center and Biology Department, Brandeis University, Waltham, United States' }]
    //   },
    //   {
    //     ORCID: 'http://orcid.org/0000-0001-9632-5448',
    //     'authenticated-orcid': true,
    //     given: 'Eve',
    //     family: 'Marder',
    //     sequence: 'additional',
    //     affiliation: [{ name: 'Volen Center and Biology Department, Brandeis University, Waltham, United States' }]
    //   }
    // ]

    if (authors.length === 1) {
      return authors.map((a) => `${a.given} ${a.family}`).join()
    }

    if (authors.length === 2) {
      return authors.map((a) => `${a.given} ${a.family}`).join(' and ')
    }

    if (authors.length < 10) {
      return authors.map((a) => `${a.given} ${a.family}`).join(', ')
    }

    if (authors.length >= 10) {
      return `${authors[0].family} et al.`
    }
  }

  if (notFound) {
    return (
      <Tooltip text='not indexed'>
        <div
          style={{
            height: '20px',
            width: '20px',
            margin: '1px',
            background: `linear-gradient(to top left,
             rgba(0,0,0,0) 0%,
             rgba(0,0,0,0) calc(50% - 0.8px),
             rgba(0,0,0,1) 50%,
             rgba(0,0,0,0) calc(50% + 0.8px),
             rgba(0,0,0,0) 100%),
         linear-gradient(to top right,
             rgba(0,0,0,0) 0%,
             rgba(0,0,0,0) calc(50% - 0.8px),
             rgba(0,0,0,1) 50%,
             rgba(0,0,0,0) calc(50% + 0.8px),
             rgba(0,0,0,0) 100%)` // rgb(220,220,220)
          }}
        />
      </Tooltip>
    )
  }

  return (
    <Tooltip
      text={
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>{title}</div>
          <hr style={{ border: '1px solid white', marginBottom: '4px' }} />
          <div>{authorsText(authors)}</div>
          <div>
            {month
              ? format(new Date(year, month - 1, 1), `'published: ' MMM yyyy`)
              : format(new Date(year, 0, 1), `'published: ' yyyy`)}
          </div>
          <div>Citations: {citationCount}</div>
        </div>
      }
    >
      <PaperSquareStyled
        percentageColor={getColorForPercentage(percentage)}
        isSelected={isSelected()}
        onClick={() => setActivePaper({ doi, title, authors, citationCount, referenceCount, month, year })}
      />
    </Tooltip>
  )
}
