import styled from 'styled-components/macro'
import { darken } from 'polished'

import { colors } from './Constants'

export const PrimaryButton = styled.button`
  border: none;
  cursor: pointer;
  color: white;
  background-color: #008b81;
  font-size: 1em;
  padding: 10px 40px;
  margin: 15px 0px 0px 0px;

  :focus {
    outline: none;
  }

  :hover {
    background-color: ${darken(0.05, colors.primary)};
  }

  transition: background-color 100ms ease-in-out;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
`

export const ConceptNavButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  color: white;
  font-size: 0.8em;
  padding: 7px 7px;
  background-color: ${({ active }) => (active ? darken(0.05, colors.primary) : colors.primary)};
  margin: 0px;

  :focus {
    outline: none;
  }

  :hover {
    background-color: ${darken(0.05, colors.primary)};
  }

  transition: background-color 100ms ease-in-out;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  @media screen and (max-width: 260px) {
    width: 100px;
    padding: 7px 8px;
  }

  @media screen and (min-width: 370px) {
    font-size: 0.8em;
    padding: 7px 9px;
  }

  @media screen and (min-width: 520px) {
    font-size: 0.85em;
    padding: 10px 12px;
  }

  @media screen and (min-width: 700px) {
    font-size: 0.9em;
    padding: 10px 15px;
  }

  @media screen and (min-width: 800px) {
    font-size: 0.95em;
    padding: 10px 20px;
  }
  @media screen and (min-width: 900px) {
    font-size: 1em;
    padding: 10px 25px;
  }
`

export const LevelNavButton = ConceptNavButton

export const ConnectionsNavButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  color: white;
  font-size: 0.8em;
  padding: 7px 7px;
  background-color: ${({ active }) => (active ? darken(0.05, colors.primary) : colors.primary)};
  margin: 0px;

  :focus {
    outline: none;
  }

  :hover {
    background-color: ${darken(0.05, colors.primary)};
  }

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  @media screen and (max-width: 260px) {
    padding: 7px 8px;
  }

  @media screen and (min-width: 370px) {
    font-size: 0.8em;
    padding: 7px 9px;
  }

  @media screen and (min-width: 520px) {
    font-size: 0.85em;
    padding: 10px 12px;
  }

  @media screen and (min-width: 700px) {
    font-size: 0.9em;
    padding: 10px 15px;
  }

  @media screen and (min-width: 800px) {
    font-size: 0.95em;
    padding: 10px 20px;
  }
  @media screen and (min-width: 900px) {
    font-size: 1em;
    padding: 10px 25px;
  }
`

export const ConnectionContextMenuButton = styled.button`
  cursor: pointer;
  font-size: 14px;
  color: ${({ remove }) => (remove ? colors.textRed : '#686f7a')}; /* TODO: change to variable*/
  border: none;
  background-color: white;
  padding: 10px;

  :focus {
    outline: none;
  }

  :hover {
    background-color: #f2f3f5;
  }

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
`
