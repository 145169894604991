import React from 'react'
import styled from 'styled-components/macro'
import Div100vh from 'react-div-100vh'

import { CircleSpinner } from 'react-spinners-kit'
import { colors } from './Constants'

export const ContainerStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: auto;

  min-height: 100vh;
  min-height: -webkit-fill-available;

  @media screen and (max-height: 530px) {
    height: auto;
  }
`

export const Container = ({ children }) => (
  <Div100vh>
    <ContainerStyled>{children}</ContainerStyled>
  </Div100vh>
)

export const ExploreContainer = styled.div`
  position: fixed;
  background-color: ${colors.exploreBackground};
  width: 100%;
  height: 100vh;
  top: 0px;
  left: 0px;
  z-index: -1;
`

export const FullPageSpinner = () => (
  <Div100vh style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <CircleSpinner size={50} color={colors.primary} />
  </Div100vh>
)

export const MainSection = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  /* iPhone Notch and bottom UI line safe-spaces  */
  padding-left: max(20px, env(safe-area-inset-left));
  padding-right: max(20px, env(safe-area-inset-right));
`

export const ConceptHeader = styled.div`
  display: flex;
  flex-direction: column;
`

const getColor = ({ isDragAccept, isDragReject, isDragActive }) => {
  if (isDragAccept) {
    return '#00e676'
  }
  if (isDragReject) {
    return '#ff1744'
  }
  if (isDragActive) {
    return '#2196f3'
  }
  return '#eeeeee'
}

export const UploadContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;

  & > span {
    font-size: 1.2em;
  }
`

export const ReferenceQuadrupletContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const FooterStyled = styled.div`
  /* iPhone Notch and bottom UI line safe-spaces  */
  /* padding-bottom: env(safe-area-inset-bottom); */
  color: white;
  padding: 20px 0;
  font-family: 'Inter', Hind, Helvetica Neue, sans-serif;
  font-weight: 200;
`

export const Footer = ({ year }) => {
  return year ? <FooterStyled>©{new Date().getFullYear()}, Noosphaera</FooterStyled> : <FooterStyled />
}

export const TimelineContainer = styled.div`
  height: 400px;
  & > * {
    height: 100%;
  }
`

export const PaperDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 700px) {
    flex-direction: row;
    padding-left: ${({ padLeft }) => (padLeft ? ' 10px' : '0px')};
  }
`

export const PaperMetadataContainer = styled.div``

export const PaperSquareStyled = styled.div`
  height: 20px;
  width: 20px;
  margin: 1px;
  background-color: ${({ percentageColor }) => percentageColor};
  box-shadow: ${({ isSelected }) => `0px 0px 0px ${isSelected ? 3 : 0}px black inset`};

  :hover {
    box-shadow: 0px 0px 0px 3px black inset;
  }

  transition: box-shadow 75ms ease-in;
`
