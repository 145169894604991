import { gql } from '@apollo/client'

export default gql`
  query {
    me {
      __typename
      ... on User {
        id
        fullName
      }
      ... on GenericError {
        message
      }
    }
  }
`
