import { gql } from '@apollo/client'

export default gql`
  query SearchConcepts($query: String!) {
    searchConcepts(query: $query) {
      ... on Concept {
        id
        name
      }
    }
  }
`
