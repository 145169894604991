import React, { useState, useEffect } from 'react'
import { Route, Link, useHistory, useRouteMatch, useLocation } from 'react-router-dom'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Graph from 'react-graph-vis'
import { ModalProvider } from 'styled-react-modal'

import { NavBar, NavSection, LogoSection, Logo, LogoText, ConceptNavBar } from '../styles/shared/NavBarsStyled'
import { ConceptModal } from '../styles/shared/ModalsStyled'
import { ConceptTitle } from '../styles/shared/TitlesStyled'
import { PrimaryButton, ConceptNavButton } from '../styles/shared/ButtonsStyled'
import { colors } from '../styles/shared/Constants'
import SearchSelectInput from '../inputs/SearchSelectInput'
import { ProfileDropdown } from '../styles/shared/DropdownsStyled'
import { SearchBar } from '../styles/shared/InputsStyled'

import { setUpNetwork, options } from '../utils/setUpNetwork'

const GraphDemo = () => {
  const nodeColors = {
    brandTeal: colors.primary,
    midGreen: 'rgb(87, 185, 157)',
    brightGreen: 'rgb(101, 200, 122)',
    yellow: 'rgb(248, 212, 81)',
    orange: 'rgb(232, 159, 60)',
    blue: 'rgb(79, 152, 213)',
    pink: 'rgb(220, 85, 146)'
  }
  const history = useHistory()
  const match = useRouteMatch()
  const location = useLocation()

  const [timer, setTimer] = useState()
  const [doubleClickTime, setDoubleClickTime] = useState(0)
  const [isConceptModalOpen, setIsConceptModalOpen] = useState(false)
  const [activeConcept, setActiveConcept] = useState('')
  const [graph, setGraph] = useState({
    nodes: [
      {
        id: 1,
        label: 'Cell Metabolism',
        color: { background: nodeColors.midGreen }
      },
      {
        id: 2,
        label: 'Catabolism',
        color: { background: nodeColors.brightGreen }
      },
      {
        id: 3,
        label: 'Anabolism',
        color: { background: nodeColors.brightGreen }
      },
      {
        id: 4,
        label: 'Electron Transport Chain',
        color: { background: nodeColors.yellow }
      },
      {
        id: 5,
        label: 'Krebs Cycle',
        color: { background: nodeColors.orange }
      },
      { id: 6, label: 'Glycolysis', color: { background: nodeColors.blue } },
      { id: 7, label: 'Protein Synthesis', color: { background: 'white' } },
      { id: 8, label: 'Translation', color: { background: nodeColors.pink } }
    ],
    edges: [
      { from: 1, to: 2, label: 'Decomposition' },
      { from: 1, to: 3, label: 'Component Of' },
      { from: 2, to: 4, label: 'Analogy' },
      { from: 2, to: 5, label: 'Application' },
      { from: 2, to: 6 },
      { from: 3, to: 7 },
      { from: 3, to: 8 }
    ]
  })

  useEffect(() => {
    console.log('graph after render: ', graph)
  }, [graph])

  const events = {
    click: (event) => {
      if (event.event.timeStamp - doubleClickTime > 250) {
        clearTimeout(timer)
        setTimer(
          setTimeout(() => {
            console.log(`click! ${new Date().getMilliseconds()}`, event)
            // Test addition of node and edge
            // TODO: create API call that retrieves connections and adds corresponding nodes and edges
            if (event.nodes.length > 0 && event.nodes[0] === 1) {
              // Cope Nodes and edges
              const nodes = Array.from(graph.nodes)
              const edges = Array.from(graph.edges)
              // end if node Homestatic Plasticity already in nodes
              if (nodes.filter((node) => node.label === 'Homeostatic Plasticity').length > 0) {
                return
              }
              // Push node and edge to copies
              nodes.push({
                id: 9,
                label: 'Homeostatic Plasticity',
                color: { background: 'mediumpurple' }
              })
              edges.push({ from: 1, to: 9, label: 'random' })
              // Spread copy of nodes and edges out in new state
              setGraph({
                ...graph,
                nodes,
                edges
              })
            }
          }, 250)
        )
      }
    },
    doubleClick: (event) => {
      console.log(`double click! ${new Date().getMilliseconds()}`, event)
      setDoubleClickTime(event.event.timeStamp)
      clearTimeout(timer)
      const { nodes } = event
      if (nodes.length !== 0) {
        const concept = graph.nodes.find((node) => node.id === nodes[0])
        setActiveConcept(concept.label)
        setIsConceptModalOpen(true)
        history.push(`${match.url}/${concept.label.replace(/\s/g, '_')}/model`)
      }
    },
    hold: (event) => {
      console.log(`hold ${new Date().getMilliseconds()}`, event)
      setDoubleClickTime(event.event.timeStamp)
      clearTimeout(timer)

      const { nodes } = event
      if (nodes.length !== 0) {
        const concept = graph.nodes.find((node) => node.id === nodes[0])

        setActiveConcept(concept.label)
        setIsConceptModalOpen(true)
        history.push(`${match.url}/${concept.label.replace(/\s/g, '_')}/model`)
      }
    }
  }

  return (
    <div
      style={{
        position: 'fixed',
        backgroundColor: 'rgb(247, 247, 247)',
        width: '100%',
        height: '100vh',
        top: '0',
        left: '0'
      }}
    >
      <NavBar>
        <LogoSection>
          <Link to='/' style={{ textDecoration: 'none', color: 'rgb(101, 107, 117)', display: 'flex' }}>
            <Logo src='https://static.noosphaera.com/noosphaera_logo_300.png' alt='Noosphaera' />
            <LogoText>noosphaera</LogoText>
          </Link>
        </LogoSection>
        <NavSection>
          <SearchBar>
            <Formik
              initialValues={{
                searchText: ''
              }}
              validationSchema={Yup.object({
                searchText: Yup.string()
              })}
              onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(true)
                // await betaSignUp({ variables: { ...values, occupation: values.occupation.value } })
                setSubmitting(false)
              }}
            >
              {() => (
                <Form>
                  <SearchSelectInput label='search...' name='search' type='text' placeholder='search...' />
                </Form>
              )}
            </Formik>
          </SearchBar>
          <div>
            <ProfileDropdown />
          </div>
        </NavSection>
      </NavBar>
      <ModalProvider>
        <Graph
          graph={graph}
          options={options}
          events={events}
          getNetwork={(network) => {
            //  if you want access to vis.js network api you can set the state in a parent component using this property
            setUpNetwork(network)
            // fromEvent(network, 'click').subscribe((whatever) => {
            //   console.log('click: ', whatever)
            // })
            // fromEvent(network, 'doubleClick').subscribe((whatever) => {
            //   console.log('doubleClick: ', whatever)
            // })
          }}
        />
        <ConceptModal
          isOpen={isConceptModalOpen}
          onBackgroundClick={() => {
            setIsConceptModalOpen(false)
            history.push(`${match.url}`)
          }}
        >
          <div
            style={{
              height: '-webkit-fill-available',
              display: 'flex',
              flexDirection: 'column',
              padding: '20px',
              justifyContent: 'space-between'
            }}
          >
            <ConceptTitle>{activeConcept ? activeConcept : 'Concept'}</ConceptTitle>

            <ConceptNavBar>
              <ConceptNavButton
                active={location.pathname.split('/').includes('discovery')}
                onClick={() => {
                  history.push(`${match.url}/${activeConcept.replace(/\s/g, '_')}/discovery`)
                }}
              >
                discovery
              </ConceptNavButton>
              <ConceptNavButton
                active={location.pathname.split('/').includes('model')}
                onClick={() => {
                  history.push(`${match.url}/${activeConcept.replace(/\s/g, '_')}/model`)
                }}
              >
                model
              </ConceptNavButton>
              <ConceptNavButton
                active={location.pathname.split('/').includes('connections')}
                onClick={() => {
                  history.push(`${match.url}/${activeConcept.replace(/\s/g, '_')}/connections`)
                }}
              >
                connections
              </ConceptNavButton>
              <ConceptNavButton
                active={location.pathname.split('/').includes('applications')}
                onClick={() => {
                  history.push(`${match.url}/${activeConcept.replace(/\s/g, '_')}/applications`)
                }}
              >
                applications
              </ConceptNavButton>
              <ConceptNavButton
                active={location.pathname.split('/').includes('questions')}
                onClick={() => {
                  history.push(`${match.url}/${activeConcept.replace(/\s/g, '_')}/questions`)
                }}
              >
                questions
              </ConceptNavButton>
            </ConceptNavBar>
            <div>
              <Route
                path={`${match.path}/:concept/discovery`}
                render={(props) => <div>{console.log('props discovery: ', props)}discovery</div>}
              />
              <Route path={`${match.path}/:concept/model`} render={() => <div>model</div>} />
              <Route path={`${match.path}/:concept/connections`} render={() => <div>connections</div>} />
              <Route path={`${match.path}/:concept/applications`} render={() => <div>applications</div>} />
              <Route path={`${match.path}/:concept/questions`} render={() => <div>questions</div>} />
            </div>
            <div>
              <PrimaryButton onClick={() => setIsConceptModalOpen(false)}>Close</PrimaryButton>
            </div>
          </div>
        </ConceptModal>
      </ModalProvider>
    </div>
  )
}

export default GraphDemo
