import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import UploadConceptVisual from './UploadConceptVisual'

import { useConceptModalStore } from '../stores/useConceptModalStore'

import { LevelNavBar } from './styles/shared/NavBarsStyled'
import { ConceptNavButton, LevelNavButton } from './styles/shared/ButtonsStyled'
import { UploadIcon } from './styles/shared/IconsStyled'
import { CircleSpinner } from 'react-spinners-kit'
import { colors } from './styles/shared/Constants'

import GET_CONCEPT_VISUALS from '../queries/GetConceptVisuals'
import { DELETE_CONCEPT_VISUAL } from '../mutations/DeleteConceptVisual'

export const ModelTab = () => {
  const [activeLevel, setActiveLevel] = useState('advanced')
  const [showUpload, setShowUpload] = useState(false)

  const concept = useConceptModalStore((state) => state.activeConcept)

  const { loading, data } = useQuery(GET_CONCEPT_VISUALS, { variables: { id: concept.id }, fetchPolicy: 'no-cache' })
  const [deleteConceptVisual] = useMutation(DELETE_CONCEPT_VISUAL, { refetchQueries: ['GetConceptVisuals'] })

  return (
    <>
      {loading && (
        <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
          <CircleSpinner color={colors.primary} size={30} />
        </div>
      )}
      {!loading && !showUpload && data?.getConceptById?.visuals.length > 0 && (
        <div
          style={{
            overflow: 'scroll',
            display: 'flex',
            margin: '20px 0px'
          }}
        >
          {data.getConceptById.visuals
            .filter(({ level }) => level === activeLevel)
            .map(({ id, url }) => (
              <div
                key={id}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '10px',
                  backgroundColor: '#f1f1f1'
                }}
              >
                <img key={id} src={url} style={{ maxHeight: '80%' }} alt='' />
                <div style={{ display: 'flex', flexDirection: 'row-reverse', width: '100%', marginTop: '10px' }}>
                  <ConceptNavButton
                    style={{ padding: '8px 12px' }}
                    onClick={async () => {
                      await deleteConceptVisual({ variables: { conceptVisualId: id } })
                    }}
                  >
                    delete
                  </ConceptNavButton>
                </div>
              </div>
            ))}
        </div>
      )}

      {showUpload && (
        <UploadConceptVisual concept={concept} level={activeLevel} closeUpload={() => setShowUpload(false)} />
      )}

      <LevelNavBar>
        {['elementary', 'advanced', 'college', 'researcher'].map((level) => (
          <LevelNavButton
            key={level}
            active={level === activeLevel}
            onClick={() => {
              setActiveLevel(level)
            }}
          >
            {level}
          </LevelNavButton>
        ))}
        <LevelNavButton
          style={{ marginLeft: '20px' }}
          onClick={() => {
            setShowUpload(!showUpload)
          }}
        >
          {!showUpload ? (
            <>
              <UploadIcon style={{ paddingRight: '5px' }} size={19} />
              upload
            </>
          ) : (
            'close upload'
          )}
        </LevelNavButton>
      </LevelNavBar>
    </>
  )
}
