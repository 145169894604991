import React from 'react'
import styled from 'styled-components/macro'
import { NavLink as ReactRouterNavLink } from 'react-router-dom'

import { colors } from './Constants'
// look at watford font

export const NavBar = styled.div`
  height: 30px;
  width: -webkit-fill-available; /* enables safe-insets */
  padding: ${({ transparent }) => (transparent ? '20px' : '10px')};
  background-color: ${({ transparent }) => (transparent ? 'transparent' : 'white')};
  /* iPhone Notch and bottom UI line safe-spaces  */
  padding-left: ${({ transparent }) =>
    transparent ? 'max(20px, env(safe-area-inset-left))' : 'max(10px, env(safe-area-inset-left))'};
  padding-right: ${({ transparent }) =>
    transparent ? 'max(20px, env(safe-area-inset-right))' : 'max(10px, env(safe-area-inset-right))'};

  display: flex;
  align-items: center;
  justify-content: space-between;

  font-family: 'Inter300', 'Hind', 'Helvetica Neue', sans-serif;
  color: white;
`

export const LogoSection = styled.div`
  display: inline-block;
  a {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  }
`

export const Logo = styled.img`
  vertical-align: middle;
  height: 30px;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
`

export const LogoText = styled.div`
  font-family: 'Hind';
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  height: 30px;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  @media screen and (max-width: 319px) {
    display: none;
  }
`

export const NavSection = styled.div`
  display: flex;
  height: 100%;
  > div:nth-child(n + 2) {
    margin-left: ${({ margin }) => (margin ? margin : '10px')};
  }
`

const NavLinkStyled = styled(ReactRouterNavLink)`
  text-decoration: none;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }

  padding: 7px 0px;

  color: ${({ gray }) => (gray ? 'rgb(101, 107, 117)' : 'white')};

  :hover {
    border-bottom: ${({ gray }) => (gray ? `2px solid ${colors.primary}` : '2px solid white')};
    padding-bottom: 5px;
  }

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
`

export const NavLink = (props) => (
  <NavLinkStyled {...props} activeStyle={{ borderBottom: '2px solid white', paddingBottom: '5px' }} />
)

export const NavIcon = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  /* :hover {
    border-bottom: ${({ hover }) => (hover ? `2px solid ${colors.primary}` : 'none')};
  } */
  cursor: pointer;
`

export const ConceptNavBar = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: visible;
  white-space: nowrap;

  @media screen and (min-width: 700px) {
    padding-left: ${({ padLeft }) => (padLeft ? ' 10px' : '0px')};
  }
`

const LevelNavBarStyled = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  white-space: nowrap;
`
export const LevelNavBar = ({ children }) => (
  <div>
    <LevelNavBarStyled>{children}</LevelNavBarStyled>
  </div>
)
