import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { GenericTextInput } from './inputs/TextInput'

import { useConceptModalStore } from '../stores/useConceptModalStore'

import { colors } from './styles/shared/Constants'
import { ConceptNavButton } from './styles/shared/ButtonsStyled'
import { CircleSpinner } from 'react-spinners-kit'

import GET_CONCEPT_PAPER_INSIGHTS from '../queries/GetConceptPaperInsights'
import ADD_CONCEPT_PAPER_INSIGHT from '../mutations/AddConceptPaperInsight'
import { DELETE_CONCEPT_PAPER_INSIGHT } from '../mutations/DeleteConceptPaperInsight'

export const ConceptPaperInsights = () => {
  const [addConceptPaperInsightMessage, setAddConceptPaperInsightMessage] = useState('')

  const [concept] = useConceptModalStore((state) => [state.activeConcept])
  const [paper] = useConceptModalStore((state) => [state.activePaper])
  const [showInputAddConceptPaperInsight, setShowInputAddConceptPaperInsight] = useConceptModalStore((state) => [
    state.showInputAddConceptPaperInsight,
    state.setShowInputAddConceptPaperInsight
  ])

  const { loading, data } = useQuery(GET_CONCEPT_PAPER_INSIGHTS, {
    fetchPolicy: 'no-cache',
    variables: { conceptId: concept.id, paperDoi: paper.doi }
  })
  const [addConceptPaperInsight] = useMutation(ADD_CONCEPT_PAPER_INSIGHT, {
    refetchQueries: ['GetConceptPaperInsights']
  })
  const [deleteConceptPaperInsight] = useMutation(DELETE_CONCEPT_PAPER_INSIGHT, {
    refetchQueries: ['GetConceptPaperInsights']
  })

  return (
    <div style={{ marginTop: '5px' }}>
      <div style={{ margin: '5px 20px 5px 20px', textAlign: 'center' }}>{addConceptPaperInsightMessage}</div>
      {!showInputAddConceptPaperInsight && (
        <ConceptNavButton
          style={{ padding: '8px 12px', marginBottom: '5px' }}
          onClick={() => {
            setShowInputAddConceptPaperInsight(true)
          }}
        >
          + insight
        </ConceptNavButton>
      )}

      {showInputAddConceptPaperInsight && (
        <div>
          <div>add insight</div>
          <Formik
            initialValues={{
              insight: ''
            }}
            validationSchema={Yup.object({
              insight: Yup.string().required('^ Required')
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true)
              const res = await addConceptPaperInsight({
                variables: { ...values, paperDoi: paper.doi, conceptId: concept.id }
              })
              setSubmitting(false)

              if (res?.data?.addConceptPaperInsight?.__typename === 'SuccessMessage') {
                resetForm()
                setShowInputAddConceptPaperInsight(false)
              }

              setAddConceptPaperInsightMessage(res?.data?.addConceptPaperInsight?.message)
              setTimeout(() => {
                setAddConceptPaperInsightMessage()
              }, 1800)
            }}
            validateOnBlur={false}
          >
            {(formikProps) => (
              <Form>
                <div style={{ margin: '5px 20px 5px 20px', textAlign: 'center' }}>{addConceptPaperInsightMessage}</div>
                <GenericTextInput
                  style={{ margin: '0px 0px 10px 0px' }}
                  errorStyle={{ marginBottom: '10px' }}
                  label='insight'
                  name='insight'
                  type='text'
                  placeholder='insight'
                />
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <ConceptNavButton
                    type='submit'
                    disabled={formikProps.isSubmitting || !formikProps.isValid}
                    style={{ padding: '8px 12px' }}
                  >
                    {formikProps.isSubmitting ? <CircleSpinner size={15} loading={true} /> : 'add insight'}
                  </ConceptNavButton>
                  <ConceptNavButton
                    style={{ padding: '8px 12px', marginLeft: '10px' }}
                    onClick={(e) => {
                      e.preventDefault()
                      setShowInputAddConceptPaperInsight(false)
                      setAddConceptPaperInsightMessage('')
                    }}
                  >
                    close + insight
                  </ConceptNavButton>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}

      {loading && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircleSpinner color={colors.primary} size={30} />
        </div>
      )}

      {!loading &&
        data?.getConceptPaperInsights.map(({ id: conceptPaperInsightId, insight }, index) => {
          return (
            <div
              key={index}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '8px 12px',
                borderBottom: `1px solid ${'rgb(220, 220, 220)'}`
              }}
            >
              <div>{insight}</div>
              <div style={{ display: 'flex', flexDirection: 'column-reverse' }}>
                <ConceptNavButton
                  style={{ padding: '8px 12px' }}
                  onClick={async () => {
                    await deleteConceptPaperInsight({ variables: { conceptPaperInsightId } })
                  }}
                >
                  delete
                </ConceptNavButton>
              </div>
            </div>
          )
        })}
    </div>
  )
}
