import { gql } from '@apollo/client'

export const DELETE_CONCEPT_VISUAL = gql`
  mutation DeleteConceptVisual($conceptVisualId: String!) {
    deleteConceptVisual(conceptVisualId: $conceptVisualId) {
      __typename
      ... on SuccessMessage {
        message
      }
      ... on GenericError {
        message
      }
    }
  }
`
