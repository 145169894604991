import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { format } from 'date-fns'
import { References } from './References'
import { ConceptPaperInsights } from './PaperInsights'

import { useConceptModalStore } from '../stores/useConceptModalStore'

import { ConceptNavBar } from './styles/shared/NavBarsStyled'
import { ConceptNavButton } from './styles/shared/ButtonsStyled'
import { PaperDetailsContainer, PaperMetadataContainer } from './styles/shared/ContainersStyled'
import { CircleSpinner } from 'react-spinners-kit'

import { objectIsEmpty } from './utils/util'

import { DELETE_CONCEPT_PAPER } from '../mutations/DeleteConceptPaper'

export const PaperDetails = () => {
  const [activePaper, setActivePaper] = useConceptModalStore((state) => [state.activePaper, state.setActivePaper])
  const [activePaperNavTab, setActivePaperNavTab] = useConceptModalStore((state) => [
    state.activePaperNavTab,
    state.setActivePaperNavTab
  ])
  const [activeConcept] = useConceptModalStore((state) => [state.activeConcept])
  const [loadingDeleteConceptPaper, setLoadingDeleteConceptPaper] = useState(false)

  const [deleteConceptPaper] = useMutation(DELETE_CONCEPT_PAPER, {
    refetchQueries: ['GetConceptPapers'],
    awaitRefetchQueries: true
  })

  const authorsText = (authors) => {
    if (authors.length === 1) {
      return authors.map((a) => `${a.given} ${a.family}`).join()
    }

    if (authors.length === 2) {
      return authors.map((a) => `${a.given} ${a.family}`).join(' and ')
    }

    if (authors.length < 10) {
      return authors.map((a) => `${a.given} ${a.family}`).join(', ')
    }

    if (authors.length >= 10) {
      return `${authors[0].family} et al.`
    }
  }

  const publicationDate = (activePaper) => {
    return activePaper.month
      ? format(new Date(activePaper.year, activePaper.month - 1, 1), `'published: ' MMM yyyy`)
      : format(new Date(activePaper.year, 0, 1), `'published: ' yyyy`)
  }

  const citationText = (activePaper) => {
    return `citations: ${
      activePaper.citationCount === 0 ? `${activePaper.citationCount} / unknown` : activePaper.citationCount
    }`
  }

  const referenceText = (activePaper) => {
    return `references: ${
      activePaper.referenceCount === 0 ? `${activePaper.referenceCount} / unknown` : activePaper.referenceCount
    }`
  }

  return (
    <>
      {!objectIsEmpty(activePaper) && (
        <PaperDetailsContainer style={{ display: 'flex', flexDirection: 'column', paddingTop: '10px' }}>
          {/* Paper info */}
          <PaperMetadataContainer style={{ paddingBottom: '10px' }}>
            <div style={{ paddingBottom: '5px' }}>
              <a href={`https://doi.org/${activePaper.doi}`} target='_blank' rel='noreferrer'>
                <strong>{activePaper.title}</strong>
              </a>
            </div>
            <div style={{ paddingBottom: '5px' }}>
              <strong>{authorsText(activePaper.authors)}</strong>
            </div>
            <div>{publicationDate(activePaper)}</div>
            <div>{citationText(activePaper)}</div>
            <div>{referenceText(activePaper)}</div>
          </PaperMetadataContainer>
          {/* TODO: convert into its own component */}
          <div>
            <ConceptNavBar style={{ justifyContent: 'space-between' }}>
              <div style={{ display: 'flex' }}>
                {['insights', 'experimental setup', 'references', 'citations'].map((tab) => (
                  <ConceptNavButton
                    key={tab}
                    active={tab === activePaperNavTab}
                    style={{ padding: '8px 12px' }}
                    onClick={() => setActivePaperNavTab(tab)}
                  >
                    {tab}
                  </ConceptNavButton>
                ))}
              </div>
              <ConceptNavButton
                style={{ marginLeft: '5px', padding: '8px 12px' }}
                onClick={async () => {
                  console.log({ paperDoi: activePaper.doi, conceptId: activeConcept.id })
                  setLoadingDeleteConceptPaper(true)
                  const res = await deleteConceptPaper({
                    variables: { paperDoi: activePaper.doi, conceptId: activeConcept.id }
                  })
                  setLoadingDeleteConceptPaper(false)
                  setActivePaper({})
                  console.log({ res })
                }}
              >
                {loadingDeleteConceptPaper ? (
                  <CircleSpinner size={15} loading={loadingDeleteConceptPaper} />
                ) : (
                  'delete paper'
                )}
              </ConceptNavButton>
            </ConceptNavBar>

            {activePaperNavTab === 'insights' && <ConceptPaperInsights />}
            {activePaperNavTab === 'experimental setup' && <div>experimental setup</div>}
            {activePaperNavTab === 'references' && (
              <div>
                <div>references</div>
                <References
                  referenceCount={activePaper?.referencesCount ? activePaper?.referencesCount : 350}
                  referenceDois={
                    activePaper
                      ? 'none'
                      : // ? activePaper?.references
                        //     .map((r) => r.doi)
                        //     .filter((r) => r !== null)
                        //     .join('__')
                        'none'
                  }
                />
              </div>
            )}
            {activePaperNavTab === 'citations' && (
              <>
                <div>
                  <div>citations</div>
                </div>
              </>
            )}
          </div>
        </PaperDetailsContainer>
      )}
    </>
  )
}
