import React from 'react'
import { Link } from 'react-router-dom'
import { NavBar, NavSection, Logo, LogoSection, NavLink } from './styles/shared/NavBarsStyled'

export const NavigationBar = () => (
  <NavBar transparent>
    <LogoSection>
      <Link to='/'>
        <Logo src='https://static.noosphaera.com/noosphaera_logo_300.png' alt='Noosphaera' />
      </Link>
    </LogoSection>
    <NavSection>
      {[
        ['learn more', '/learn-more'],
        ['beta', '/signup'],
        ['log in', '/login']
      ].map(([title, path]) => (
        <div key={path} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <NavLink to={path}>{title}</NavLink>
        </div>
      ))}
    </NavSection>
  </NavBar>
)
