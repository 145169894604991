import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloClient, InMemoryCache, HttpLink, ApolloProvider } from '@apollo/client'
import { onError } from '@apollo/client/link/error'

import AppRouter from './routers/AppRouter'

const errorLink = new onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      // console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      // Case UNAUTHENTICATED redirect to login
      if (error.extensions !== undefined && error.extensions.code === 'UNAUTHENTICATED') {
        // TODO: redirect to log in page
        if (process.env.NODE_ENV === 'development') {
          console.log('AuthenticationError: redirect to Log In page')
        }

        return
      }

      if (process.env.NODE_ENV === 'development') {
        console.log('Unknown error')
        console.log('Error object:', error)
      }
    })
  }
  if (networkError) {
    // console.log(`[Network error]: ${networkError}`)
    // TODO: add modal that warns about no network
  }
})

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_HOST,
  credentials: 'include'
})

const link = errorLink.concat(httpLink)

const cache = new InMemoryCache()

const client = new ApolloClient({
  link,
  cache,
  defaultOptions: {
    mutate: { errorPolicy: 'all' }
  }
})

const Root = () => (
  <ApolloProvider client={client}>
    <AppRouter />
  </ApolloProvider>
)

ReactDOM.render(<Root />, document.getElementById('root'))
