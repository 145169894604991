import { gql } from '@apollo/client'

export default gql`
  mutation BetaSignUp(
    $firstName: String!
    $lastName: String!
    $email: String!
    $occupation: String!
    $organization: String!
  ) {
    betaSignUp(
      firstName: $firstName
      lastName: $lastName
      email: $email
      occupation: $occupation
      organization: $organization
    )
  }
`
