import { gql } from '@apollo/client'

export default gql`
  mutation CreateConcept($name: String!) {
    createConcept(name: $name) {
      ... on Concept {
        id
        name
      }
    }
  }
`
