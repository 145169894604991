import styled from 'styled-components/macro'

import { objectIsEmpty } from '../../utils/util'

export const ConnectionsContextMenu = styled.div`
  display: ${({ open }) => (open ? 'flex' : 'none')};
  position: absolute;
  top: ${({ position }) => (!objectIsEmpty(position) ? `${position.y + 50}px` : '50px')};
  left: ${({ position }) => (!objectIsEmpty(position) ? `${position.x}px` : '50px')};
  padding-top: 5px;
  background-color: white;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 2px 6px 2px rgba(60, 64, 67, 0.15);
  flex-direction: column;
`
