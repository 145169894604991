import React from 'react'
import AsyncSelect from 'react-select/async-creatable'
import { components } from 'react-select'

import { SearchIcon } from '../styles/shared/IconsStyled'
import { navBarStyles } from '../styles/SearchSelectStyles'

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <SearchIcon size={20} />
  </components.DropdownIndicator>
)

const SearchSelectInput = ({ onChange, loadOptions, name, ...rest }) => {
  return (
    <AsyncSelect
      styles={navBarStyles}
      components={{ DropdownIndicator }}
      placeholder='search...'
      onChange={onChange}
      loadOptions={loadOptions}
      {...rest}
    />
  )
}

export default SearchSelectInput
