import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'
import {
  Dropdown as DropdownStyled,
  DropdownItem as DropdownItemStyled,
  DropdownMenu as DropdownMenuStyled,
  DropdownDivider as DropdownDividerStyled
} from 'styled-dropdown-component'
import { NavIcon } from './NavBarsStyled'
import { FaRegUserCircle } from 'react-icons/fa'
import { colors } from './Constants'

const Dropdown = styled(DropdownStyled)`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  :hover {
    border-bottom: ${({ hover }) => (hover ? `2px solid ${colors.primary}` : 'none')};
  }
`
const DropdownItem = styled(DropdownItemStyled)`
  color: #686f7a;
  padding: 2px 8px;
  touch-action: manipulation;
  cursor: pointer;
  :hover {
    color: #686f7a;
  }
`

const DropdownDivider = styled(DropdownDividerStyled)`
  margin-top: 5px;
`

const DropdownMenu = styled(DropdownMenuStyled)`
  font-size: 16px;
  margin: 5px -5px 0px 0px;
  border-radius: 0px;
  min-width: 120px;
  touch-action: manipulation;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 2px 6px 2px rgba(60, 64, 67, 0.15);
  border: none;
`

export const ProfileDropdown = ({ openSettings }) => {
  const history = useHistory()
  const [hidden, setHidden] = useState(true)

  return (
    <Dropdown>
      <NavIcon>
        <FaRegUserCircle size='21px' color={colors.primary} dropdowntoggle='true' onClick={() => setHidden(!hidden)} />
      </NavIcon>

      <DropdownMenu right hidden={hidden} toggle={() => setHidden(!hidden)}>
        <DropdownItem onClick={() => history.push('/profile')}>Profile</DropdownItem>
        <DropdownItem
          onClick={() => {
            setHidden(true)
            openSettings()
          }}
        >
          Settings
        </DropdownItem>
        <DropdownDivider />
        <DropdownItem style={{ color: colors.textRed }} onClick={() => history.push('/logout')}>
          Log Out
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}
