import React, { useState, useCallback, useEffect } from 'react'
import axios from 'axios'

import { useConceptModalStore } from '../stores/useConceptModalStore'

import { PaperSquare } from './PaperSquare'
import { ReferenceQuadrupletContainer } from './styles/shared/ContainersStyled'
import { colors } from './styles/shared/Constants'
import { sleep } from './utils/util'

import referencesJSON from './data.json'

export const References = ({ referenceDois, referenceCount }) => {
  const [loading, setLoading] = useState(true)
  const [references, setReferences] = useState([])
  const [referencesSortOrder] = useConceptModalStore((state) => [state.referencesSortOrder])

  console.log({ referenceDois, referenceCount })

  const chunk = (array, size) => {
    const chunkedArray = []
    let index = 0
    while (index < array.length) {
      chunkedArray.push(array.slice(index, size + index))
      index += size
    }
    return chunkedArray
  }

  const getReferenceCounts = useCallback(async () => {
    setLoading(true)
    const getReferenceCount = (doi) =>
      new Promise((resolve, reject) => {
        axios
          .get(`https://api.crossref.org/works/${doi}`, { validateStatus: () => true })
          .then(
            ({
              data: {
                message: {
                  'reference-count': referenceCount,
                  'is-referenced-by-count': citationCount,
                  author,
                  title: [title],
                  'published-print': publishedPrint,
                  created
                }
              }
            }) => {
              let date = publishedPrint ? publishedPrint : created
              resolve({
                doi,
                title,
                referenceCount,
                citationCount,
                author,
                year: date['date-parts'][0][0],
                month: date['date-parts'][0][1]
              })
            }
          )
          .catch(() => resolve())
      })

    const referenceDoisArray = referenceDois.split('__')
    console.log({ referenceDoisArray })
    if (referenceDoisArray.length === 0) return
    //   /* all at once */
    //   // let referenceCountRequests = []

    //   // referenceDois.forEach((doi) => referenceCountRequests.push(getReferenceCount(doi)))

    //   // Promise.all(referenceCountRequests).then((allData) => {
    //   //   console.log('allData: ', allData)
    //   //   setLoading(false)
    //   // })
    //   /* all at once */

    //   /* capped in parallel batches */
    const concurrencyLimit = 40
    let results = []
    const batchesCount = Math.ceil(referenceDoisArray.length / concurrencyLimit)

    console.time('batches')
    for (let i = 0; i < batchesCount; i++) {
      console.time('batch')
      const batchStart = i * concurrencyLimit
      const batchDois = referenceDoisArray.slice(batchStart, batchStart + concurrencyLimit)
      console.log({ batchDois })
      const batchPromises = batchDois.map((doi) => getReferenceCount(doi))

      const batchResults = await Promise.all(batchPromises)
      await sleep(1000)
      console.timeEnd('batch')
      console.log(`end of batch ${i}`)

      results = results.concat(batchResults)
    }

    results = results.filter((el) => el != null)

    console.timeEnd('batches')
    console.log({ results })
    setReferences(
      results.concat(
        Array.apply(null, Array(referenceCount - results.length)).map(() => ({
          notFound: true,
          year: -Infinity,
          citationCount: -Infinity
        }))
      )
    )
    setLoading(false)
  }, [referenceDois, referenceCount])

  useEffect(() => {
    getReferenceCounts(referenceDois)
  }, [getReferenceCounts, referenceDois, referenceCount])

  if (loading)
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: colors.exploreBackground,
          border: '5px solid',
          borderColor: colors.exploreBackground
        }}
      >
        loading...
      </div>
    )

  if (references.length === 0) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: colors.exploreBackground,
          border: '5px solid',
          borderColor: colors.exploreBackground
        }}
      >
        <div>no papers added yet...</div>
        <div>add one!</div>
      </div>
    )
  }

  if (references.length > 0) {
    return (
      <>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: colors.exploreBackground,
            overflowX: 'scroll',
            border: '5px solid',
            borderColor: colors.exploreBackground,
            direction: 'rtl'
          }}
        >
          {chunk(
            referencesSortOrder === 'year'
              ? references.sort((refA, refB) => refB.year - refA.year)
              : references.sort((refA, refB) => refB.citationCount - refA.citationCount),
            4
          ).map((referenceQuadruplet, quadrupletIndex) => (
            <ReferenceQuadrupletContainer key={quadrupletIndex}>
              {referenceQuadruplet.map((reference, index) => {
                let percentage = reference.citationCount / Math.max(...references.map((r) => r.citationCount))
                percentage = isNaN(percentage) ? 0 : percentage

                return reference?.notFound ? (
                  <PaperSquare key={index} notFound />
                ) : (
                  <PaperSquare
                    key={index}
                    doi={reference.doi}
                    title={reference.title}
                    authors={reference.author}
                    count={reference.citationCount}
                    year={reference.year}
                    month={reference.month}
                    referenceCount={reference.referenceCount}
                    citationCount={reference.citationCount}
                    percentage={percentage}
                  />
                )
              })}
            </ReferenceQuadrupletContainer>
          ))}
        </div>
      </>
    )
  }
}
