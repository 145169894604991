import { gql } from '@apollo/client'

export default gql`
  query GetConceptPapers($conceptId: String!) {
    getConceptPapers(conceptId: $conceptId) {
      __typename
      ... on Paper {
        id
        doi
      }
      ... on GenericError {
        message
      }
    }
  }
`
