import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'
import Graph from 'react-graph-vis'
import { ModalProvider } from 'styled-react-modal'
import { use100vh } from 'react-div-100vh'
import { ConceptModal } from '../ConceptModal'
import { ConnectionModal } from '../ConnectionModal'

import { useConceptModalStore } from '../../stores/useConceptModalStore'
import { useGraphStore } from '../../stores/useGraphStore'

import { ExploreContainer } from '../styles/shared/ContainersStyled'
import { NavBar, NavSection, LogoSection, Logo, LogoText } from '../styles/shared/NavBarsStyled'
import { SettingsModal, SpecialModalBackground } from '../styles/shared/ModalsStyled'
import { PrimaryButton, ConnectionContextMenuButton } from '../styles/shared/ButtonsStyled'
import SearchBarSelect from '../inputs/SearchBarSelect'
import { ProfileDropdown } from '../styles/shared/DropdownsStyled'
import { SearchBar } from '../styles/shared/InputsStyled'
import { ConnectionsContextMenu } from '../styles/shared/ContextMenusStyled'

import { setUpNetwork, options } from '../utils/setUpNetwork'
import { getRandomNodeColor } from '../utils/explore'
import { objectIsEmpty, toTitleCase } from '../utils/util'

import SEARCH_CONCEPTS from '../../queries/SearchConcepts'
import { GET_CONNECTIONS } from '../../queries/GetConnections'
import CREATE_CONCEPT from '../../mutations/CreateConcept'

const ExplorePage = () => {
  const [searchDebounce, setSearchDebounce] = useState({})
  const [isConnectionsContextMenuOpen, setIsConnectionsContextMenuOpen] = useState(false)
  const [connectionsContextMenuPosition, setConnectionsContextMenuPosition] = useState({})
  const [isConceptModalOpen, setIsConceptModalOpen] = useState(false)
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false)
  const [isConnectionModalOpen, setIsConnectionModalOpen] = useState(false)
  const [activeConcept, setActiveConcept] = useConceptModalStore((state) => [
    state.activeConcept,
    state.setActiveConcept
  ])
  const [setActivePaper] = useConceptModalStore((state) => [state.setActivePaper])
  const [activeConnection, setActiveConnection] = useState({})
  const [visNetwork, setVisNetwork] = useState()
  const [graph, setGraph] = useGraphStore((state) => [state.graph, state.setGraph])

  const [searchConcepts] = useLazyQuery(SEARCH_CONCEPTS)
  const [getConnections] = useLazyQuery(GET_CONNECTIONS, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only'
  })
  const [createConcept] = useMutation(CREATE_CONCEPT)

  const modalBackgroundHeight = use100vh()

  // searchDebounce handler
  useEffect(() => {
    const { callback, delay } = searchDebounce
    if (callback) {
      const timeout = setTimeout(callback, delay)
      return () => clearTimeout(timeout)
    }
  }, [searchDebounce])

  const loadOptions = (query, reactSelectCallback) => {
    // NOTE: Implementation from https://gitmemory.com/issue/JedWatson/react-select/614/621579510
    setSearchDebounce({
      callback: async () => {
        const res = await searchConcepts({ variables: { query } })

        const conceptOptions = res.data.searchConcepts.map((concept) => ({
          ...concept,
          value: concept.id,
          label: concept.name
        }))
        reactSelectCallback(conceptOptions)
      },
      delay: 150
    })
  }

  const events = {
    click: (event) => {
      setIsConnectionsContextMenuOpen(false)
      setIsConnectionModalOpen(false)
      if (event.nodes.length > 0) {
        const concept = graph.nodes.find((node) => node.id === event.nodes[0])
        if (concept.id !== activeConcept.id) setActivePaper({})
        setActiveConcept(concept)
        setConnectionsContextMenuPosition({ ...event.pointer.DOM })
        setIsConnectionsContextMenuOpen(true)
      }
      if (event.edges.length > 0) {
        const connection = graph.edges.find((edge) => edge.id === event.edges[0])
        setActiveConnection(connection)
        setActiveConcept({})
        setIsConnectionModalOpen(true)
      }
    },
    oncontext: (event) => {
      event.event.preventDefault()

      setIsConnectionsContextMenuOpen(false)
      const node = visNetwork.body.data.nodes._data.get(visNetwork.getNodeAt(event.pointer.DOM))

      if (node) {
        visNetwork.selectNodes([node.id])
        const concept = graph.nodes.find((n) => n.id === node.id)
        setActiveConcept(concept)
        setIsConceptModalOpen(true)
      }
    },
    hold: (event) => {
      setIsConnectionsContextMenuOpen(false)
      const { nodes } = event

      if (nodes.length !== 0) {
        const concept = graph.nodes.find((node) => node.id === nodes[0])
        setActiveConcept(concept)
        setIsConceptModalOpen(true)
      }
    }
  }

  const formatCreateLabel = (inputValue) => `create concept "${toTitleCase(inputValue)}"`

  return (
    <ExploreContainer>
      <NavBar>
        <LogoSection>
          <Link to='/' style={{ textDecoration: 'none', color: 'rgb(101, 107, 117)', display: 'flex' }}>
            <Logo src='https://static.noosphaera.com/noosphaera_logo_300.png' alt='Noosphaera' />
            <LogoText>noosphaera</LogoText>
          </Link>
        </LogoSection>
        <NavSection>
          <SearchBar>
            <SearchBarSelect
              loadOptions={loadOptions}
              formatCreateLabel={formatCreateLabel}
              onChange={async (e) => {
                let searchConcept = {}

                if (e.__isNew__) {
                  const {
                    data: {
                      createConcept: { id, name }
                    }
                  } = await createConcept({ variables: { name: toTitleCase(e.value) } })
                  searchConcept = { id, name }
                } else {
                  searchConcept = e
                }
                // add to graph
                if (!objectIsEmpty(searchConcept)) {
                  let nodes = Array.from(graph.nodes)

                  nodes.push({
                    id: searchConcept.id,
                    label: searchConcept.name,
                    color: { background: getRandomNodeColor() }
                  })

                  setGraph({ ...graph, nodes })
                }
              }}
            />
          </SearchBar>
          <div>
            <ProfileDropdown
              openSettings={() => {
                setIsSettingsModalOpen(true)
              }}
            />
          </div>
        </NavSection>
      </NavBar>
      <ModalProvider backgroundComponent={SpecialModalBackground}>
        <Graph
          graph={graph}
          options={options}
          events={events}
          getNetwork={(network) => {
            //  if you want access to vis.js network api you can set the state in a parent component using this property
            setUpNetwork(network)
            setVisNetwork(network)
          }}
        />

        <ConnectionsContextMenu open={isConnectionsContextMenuOpen} position={connectionsContextMenuPosition}>
          {[
            { type: 'components', label: 'component', buttonText: 'components' },
            { type: 'componentOf', label: 'component of', buttonText: 'component of' },
            { type: 'analogies', label: 'analogy', buttonText: 'analogies' },
            { type: 'applications', label: 'application', buttonText: 'application' },
            { type: 'applicationOf', label: 'application of', buttonText: 'application of' },
            { type: 'remove', label: 'remove', buttonText: 'remove' }
          ].map(({ type, buttonText }) => (
            <ConnectionContextMenuButton
              key={type}
              remove={type === 'remove'}
              onClick={async () => {
                if (type === 'remove') {
                  setIsConnectionsContextMenuOpen(false)
                  const nodes = Array.from(graph.nodes).filter(({ id }) => id !== activeConcept.id)
                  const edges = Array.from(graph.edges).filter(
                    ({ from, to }) => from !== activeConcept.id || to !== activeConcept.id
                  )

                  // Spread copy of nodes and edges out in new state
                  setGraph({
                    ...graph,
                    nodes,
                    edges
                  })
                } else {
                  const res = await getConnections({ variables: { conceptId: activeConcept.id, type } })

                  setIsConnectionsContextMenuOpen(false)
                  const nodes = Array.from(graph.nodes)
                  const edges = Array.from(graph.edges)

                  const nodeIds = nodes.map(({ id }) => id)
                  const edgeIds = edges.map(({ id }) => id)

                  res.data.getConnections.forEach((connection) => {
                    const newConcept = activeConcept.id === connection.from.id ? connection.to : connection.from

                    if (!nodeIds.includes(newConcept.id)) {
                      nodes.push({
                        id: newConcept.id,
                        label: newConcept.name,
                        color: { background: getRandomNodeColor() }
                      })
                    }

                    if (!edgeIds.includes(connection.id)) {
                      edges.push({
                        id: connection.id,
                        from: connection.from.id,
                        to: connection.to.id,
                        label: connection.type
                      })
                    }
                  })
                  // Spread copy of nodes and edges out in new state
                  setGraph({
                    ...graph,
                    nodes,
                    edges
                  })
                }
              }}
            >
              {buttonText}
            </ConnectionContextMenuButton>
          ))}
        </ConnectionsContextMenu>

        {isConceptModalOpen && (
          <ConceptModal
            isOpen={isConceptModalOpen}
            onBackgroundClick={() => {
              setIsConceptModalOpen(false)
              // setActiveConcept({})
              // setActivePaper({})
            }}
            backgroundProps={{ height: `${modalBackgroundHeight}px` }}
            concept={activeConcept}
          />
        )}

        {isConnectionModalOpen && (
          <ConnectionModal
            isOpen={isConnectionModalOpen}
            onBackgroundClick={() => {
              setIsConnectionModalOpen(false)
            }}
            backgroundProps={{ height: `${modalBackgroundHeight}px` }}
            connection={activeConnection}
            closeModal={() => setIsConnectionModalOpen(false)}
          />
        )}

        {isSettingsModalOpen && (
          <SettingsModal
            isOpen={isSettingsModalOpen}
            onBackgroundClick={() => {
              setIsSettingsModalOpen(false)
            }}
            backgroundProps={{ height: `${modalBackgroundHeight}px` }}
          >
            App version: {process.env.REACT_APP_VERSION}
            <div>
              <PrimaryButton
                onClick={() => {
                  window.location.reload()
                }}
              >
                update app
              </PrimaryButton>
            </div>
          </SettingsModal>
        )}
      </ModalProvider>
    </ExploreContainer>
  )
}

export default ExplorePage
